<ng-template #headerRef>
  <h5 class="client-orders-modal__title modal-title">Отмена консультации</h5>
</ng-template>
<app-modal [header]="headerRef" (destroy)="dismiss()">
  <div modal-content class="client-orders-modal__content">
    <div class="client-orders-modal__subtitle">
      Вы точно хотите отменить запись?
    </div>
    <app-preloader
            *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.CHANGE_SESSION
                  )
                ].value;else btn">
    </app-preloader>
    <ng-template #btn>
      <button (click)="send()" class="client-orders-modal__btn modal-submit btn-custom btn-approve btn-orange">Отменить</button>
      <button (click)="dismiss()" class="client-orders-modal__btn_none">Оставить</button>
    </ng-template>
  </div>
</app-modal>

