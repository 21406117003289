import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeRu from '@angular/common/locales/ru';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './features/main/main.component';
import { UIModule } from './ui/ui.module';
import { LandingComponent } from './features/main/landing/landing.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ExpertsComponent } from './features/main/experts/experts.component';
import { AboutComponent } from './features/main/about/about.component';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeRu, 'ru');

import { register } from 'swiper/element/bundle';
import { AdvantagesComponent } from './features/main/landing/advantages/advantages.component';
import { ReviewsComponent } from './features/main/landing/reviews/reviews.component';
import { NgxPaginationModule } from "ngx-pagination";
import { MainInterceptor } from './services/helpers/main.interceptor';
import { ErrorInterceptor } from './services/helpers/error.interecepter';
import { provideImagePathPrefix } from './directives/image.directive';
import { environment } from 'src/environments/environment';
import { FaqComponent } from './features/main/faq/faq.component';
import { PublicExpertComponent } from './features/main/public-expert/public-expert.component';
import { VacancyComponent } from './features/main/vacancy/vacancy.component';
import { ContactsComponent } from './features/main/contacts/contacts.component';
import {ReactiveFormsModule} from "@angular/forms";

register();

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LandingComponent,
        ExpertsComponent,
        AboutComponent,
        FaqComponent,
        AdvantagesComponent,
        ReviewsComponent,
        PublicExpertComponent,
        VacancyComponent,
        ContactsComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    UIModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxPaginationModule,
    ReactiveFormsModule

  ],
    providers: [
      HttpClient,
      { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      provideImagePathPrefix(environment.apiUrl),
      {
        provide: LOCALE_ID,
        useValue: 'ru',
      },
    ],
    bootstrap: [AppComponent],
    exports: [
        // HowWorksSliderComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
