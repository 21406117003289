import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-preloader',
  templateUrl: './image-preloader.component.html',
  styleUrls: ['./image-preloader.component.scss']
})
export class ImagePreloaderComponent {
  @Input() imageLink: string | undefined = undefined;
  @Input() alt: string = '';
  @Input() minWidthPx: number = 10;

  isImageLoaded = false;
  isImageError = false;

  onLoaded() {
    // setTimeout(() => {
      this.isImageLoaded = true;
    // }, 100);
   
    console.log("loaded")
  }

  onError() {
    this.isImageError = true;
    console.log("error")
  }
}
