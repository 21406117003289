<section class="swiper-section">
  <div class="my-slider" >
    <swiper-container
      class="swiper-container"
      autoHeight
      #swiper
    >
      <ng-content></ng-content>
    </swiper-container>
  </div>
  <div class="nav">
    <button
      (click)="prevSlide($event)" class="nav__button prev-button">❮</button>
    <div class="pagination-el">
      <button
        [ngClass]="{'active':idx === currentIndex}"
        *ngFor="let item of ArrayCountOfSlide;let idx=index"
        (click)="GoToSlide(idx)"

        class="page-button"></button>
    </div>
    <button
      (click)="nextSlide($event)" class="nav__button next-button">❯</button>
  </div>
</section>

