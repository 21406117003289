<ng-template #headerRef>
  <h5 class="client-info-modal__title modal-title">Информация о клиенте</h5>
</ng-template>
<app-modal [header]="headerRef" (destroy)="dismiss()" *ngIf="client?.() as cl">
  <div modal-content class="client-info-modal__content" [formGroup]="form">
    <h5 class="information-info__title">
      {{ cl.name }}
    </h5>
    <ul class="information-info__grid">
      <div class="information-info__text information-info__text_grey">
        Адрес электронной почты
      </div>
      <div class="information-info__text">
        {{ cl.email }}
      </div>
      <div class="information-info__text information-info__text_grey">
        Телефон
      </div>
      <div class="information-info__text">
        {{ cl.phone }}
      </div>
      <div class="information-info__text information-info__text_grey">
        Дата рождения
      </div>
      <div class="information-info__text">
        {{ cl.birthdayDate | date : "dd/MM/yyyy" }}
      </div>
      <div class="information-info__text information-info__text_grey">
        Место рождения
      </div>
      <div class="information-info__text">
        {{ cl.place.result }}
      </div>
      <div class="information-info__text information-info__text_grey">
        Время рождения
      </div>
      <div class="information-info__text">
        Точное время: {{ cl.birthdayTime }}
      </div>
      <div class="information-info__text information-info__text_grey">
        Часовой пояс
      </div>
      <div class="information-info__text">
        GMT{{ cl.timezone < 0 ? cl.timezone : "+" + cl.timezone }}
      </div>
    </ul>
    <div class="client-info-modal__wrap input-wrap input-wrap_position">
      <label class="input-label">Комментарий о клиенте</label>
      <textarea
        formControlName="comment"
        class="input-textarea input-custom"
        type="text"
        maxlength="250"
        placeholder="Оставить комментарий"
      ></textarea>
      <div class="input-textarea__count">
        {{ form.get("comment")!.value!["length"] }}/250
      </div>
    </div>
    <h5 class="client-info-modal__title modal-title">
      Консультация с клиентом
    </h5>
    <div class="client-info-modal-tab">
      <div class="client-info-modal-tab__tabs">
        <div
          (click)="ChangeTab(0)"
          class="client-info-modal-tab__tab"
          [ngClass]="{ 'client-info-modal-tab__tab_active': activeTab() === 0 }"
        >
          Предстоящие
        </div>
        <div
          (click)="ChangeTab(1)"
          class="client-info-modal-tab__tab"
          [ngClass]="{ 'client-info-modal-tab__tab_active': activeTab() === 1 }"
        >
          Завершенные
        </div>
      </div>
      <div class="client-info-modal-tab__content">
        <ul class="client-info-modal-tab__list">
          <li class="client-info-modal-tab__item" *ngFor="let item of list()">
            <div
              class="client-info-modal-tab-service expert-clients-service custom-table-service"
            >
              <h5
                class="expert-clients-service__title custom-table-service__title"
              >
                Консультация: {{ specialization() }}
              </h5>
              <div
                class="expert-clients-service__name custom-table-service__name"
              >
                Клиент:&nbsp;
                <button
                  class="expert-clients-service__link custom-table-service__link"
                >
                  {{ cl.name }}
                </button>
              </div>
            </div>
            <div class="client-info-modal-tab__text">
              {{ item["dateTime"] | date : "dd.MM.YYYY" : "GMT+3" }}
            </div>
            <div class="client-info-modal-tab__text">
              {{ item["dateTime"] | date : "HH:mm" : "GMT+3" }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <app-preloader
      *ngIf="
        loadingService.States[
          loadingService.getIdFromArrayStateByType(
            loadingService.StatesNames.SET_COMMENT
          )
        ].value;
        else info
      "
    >
    </app-preloader>
    <ng-template #info>
      <button
        class="client-info-modal__btn modal-submit btn-custom btn-approve btn-orange"
        (click)="SendComment()"
      >
        Сохранить
      </button>
      <button
        class="client-info-modal__btn_none btn-custom btn-none"
        (click)="dismiss()"
      >
        Закрыть
      </button>
    </ng-template>
  </div>
</app-modal>
