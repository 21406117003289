<p *ngIf="type==='SESSION_CANCELLED' && role === 'client'">
    {{additionalInfo?.timeAt|date:'dd.MM.YYYY HH:mm':'GMT+3'}} консультация у {{additionalInfo['recipientName']}} отменена
</p>
<p *ngIf="type==='SESSION_CANCELLED' && role === 'expert'">
    {{additionalInfo['recipientName']}} отменил консультацию {{additionalInfo?.timeAt|date:'dd.MM.YYYY HH:mm':'GMT+3'}}
</p>
<p *ngIf="type==='SESSION_REMIND' && role === 'client'">
    Напоминаем: Вы записаны на {{additionalInfo?.sessionDate|date:'HH:mm':'GMT+3'}} к {{additionalInfo['recipientName']}}.
    Хорошей консультации!
</p>
<p *ngIf="type==='SESSION_REMIND' && role === 'expert'">
    Напоминаем: к Вам записан на {{additionalInfo?.sessionDate|date:'HH:mm':'GMT+3'}} {{additionalInfo['recipientName']}}. Хорошей консультации!”
</p>
<p *ngIf="type==='NEW_SESSION' && role === 'expert'">
    {{additionalInfo?.sessionDate|date:'dd.MM.YYYY HH:mm':'GMT+3'}} {{additionalInfo['recipientName']}} забронировал консультацию
</p>
<p *ngIf="type==='PAYMENT_COMPLETE' && role === 'client'">
    Вы приобрели пакет “{{additionalInfo?.name}}”
</p>
<p *ngIf="type==='PAYMENT_CANCELLED' && role === 'client'">
    Отмена оплаты ““{{additionalInfo?.name}}””
</p>



<p *ngIf="type==='SESSION_MOVED' && role === 'expert'">
    {{additionalInfo['recipientName']}} перенес консультацию с {{additionalInfo?.from|date:'dd.MM.YYYY HH:mm':'GMT+3'}} на {{additionalInfo?.to|date:'dd.MM.YYYY HH:mm':'GMT+3'}}
</p>
<p *ngIf="type==='SESSION_MOVED' && role === 'client'">
    Консультация у {{additionalInfo['recipientName']}} перенесена с {{additionalInfo?.from|date:'dd.MM.YYYY HH:mm':'GMT+3'}} на {{additionalInfo?.to|date:'dd.MM.YYYY HH:mm':'GMT+3'}}
</p>
<p *ngIf="type==='LEVEL_CHANGED' && role === 'expert'">
    Ваш уровень изменился: теперь вы обладаете {{additionalInfo?.newLevel}} уровнем
</p>
<p *ngIf="type==='TARIFF_CHANGED' && role === 'expert'">
    Ваш статус изменился: теперь вы оказываете услуги по пакету "{{additionalInfo?.tariffName}}"
</p>








