import {
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  InjectionToken,
  Input,
  Provider,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent, take } from 'rxjs';

export const IMAGE_PATH_PREFIX = new InjectionToken<string>(
  'dz-img path prefix'
);

export function provideImagePathPrefix(prefix: string): Provider {
  return {
    provide: IMAGE_PATH_PREFIX,
    useValue: prefix,
  };
}

@Directive({ selector: 'img[dz-img]', standalone: true })
export class ImageDirective {
  private readonly destroyRef = inject(DestroyRef);
  private readonly elementRef: ElementRef<HTMLImageElement> =
    inject(ElementRef);

  private readonly prefix = inject(IMAGE_PATH_PREFIX);

  constructor() {
    fromEvent(this.elementRef.nativeElement, 'error')
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          if (this.fallback) {
            this.elementRef.nativeElement.src = this.fallback;
          }
        },
      });
  }

  @Input()
  skipPrefix: boolean = false;

  @Input({ required: true })
  src!: string | null;

  @Input()
  fallback: string = './assets/img/empty_avatar_expert_card.svg';

  @HostBinding('src')
  get imgUrl() {
    if (!this.src) {
      return this.fallback;
    }

    if (this.skipPrefix) {
      return this.src;
    }

    return `${this.prefix}${this.src || ''}`;
  }
}
