import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum LoadingStates {
  UNKNOWN = 'UNKNOWN',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REGISTER = 'REGISTER',
  REGISTER_VALIDATE = 'REGISTER_VALIDATE',
  GLOBAL = 'GLOBAL',
  SEND_SMS = 'SEND_SMS',
  VERIFY_CODE = 'VERIFY_CODE',
  SEND_FORM = 'SEND_FORM',
  GET_EXPERTS = 'GET_EXPERTS',
  GET_EXPERT = 'GET_EXPERT',
  ADD_EDUCATION_IMAGE = 'ADD_EDUCATION_IMAGE',
  EDIT_PROFILE = 'EDIT_PROFILE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  EDIT_EDUCATION = 'EDIT_EDUCATION',
  EDIT_AVATAR = 'EDIT_AVATAR',
  GET_EDUCATIONS = 'GET_EDUCATIONS',
  GET_PAY_HISTORY = 'GET_PAY_HISTORY',
  GET_BALANCE = 'GET_BALANCE',
  GET_PACKAGES = 'GET_PACKAGES',
  CHECK_PROMO = 'CHECK_PROMO',
  GET_ARTICELS = 'GET_ARTICELS',
  GET_CIENTS_LIST = 'GET_CIENTS_LIST',
  GET_CIENTS_LIST_LAST_MONTH = 'GET_CIENTS_LIST_LAST_MONTH',
  GET_CIENTS_LIST_CURRENT_MONTH = 'GET_CIENTS_LIST_CURRENT_MONTH',
  GET_SESSIONS_OF_CLIENT = 'GET_SESSIONS_OF_CLIENT',
  SET_COMMENT = 'SET_COMMENT',
  CHANGE_SESSION = 'CHANGE_SESSION',
  MOVE_SESSION = 'MOVE_SESSION',
  GET_STATISTICS ='GET_STATISTICS',
  GET_STATISTICS_MORE = 'GET_STATISTICS_MORE',
  GET_STATISTICS_TOTAL = 'GET_STATISTICS_TOTAL',
  GET_LIFE_QUESTIONS = 'GET_LIFE_QUESTIONS',
  POST_SEND_EMAIL = 'POST_SEND_EMAIL'
}

export interface LoadingInterface {
  id: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor() {}

  StatesNames = LoadingStates;
  States = Object.keys(LoadingStates).map((x) => {
    return { state: x, value: false };
  });
  Loadings: LoadingInterface[] = [];
  IsLoadingChange$ = new Subject<boolean>();

  addLoading(requestType = LoadingStates.UNKNOWN): string {
    const id = this.uuidv4();
    this.Loadings.push({
      id,
      type: requestType,
    });
    this.States.find((x) => x.state == requestType)!.value = true;
    this.IsLoadingChange$.next(true);
    return id;
  }

  removeLoading(id: string): LoadingInterface[] {
    const type = this.Loadings.find((x) => x.id == id)!.type;
    this.Loadings = this.Loadings.filter((f) => f.id != id);

    let countCurrentType = this.Loadings.filter((x) => x.type == type).length;
    this.States.find((x) => x.state == type)!.value =
      countCurrentType > 0 ? true : false;
    this.IsLoadingChange$.next(true);

    return this.Loadings;
  }

  isHasLoadingByType(requestType: LoadingStates) {
    return this.Loadings.findIndex((x) => x.type == requestType) > -1
      ? true
      : false;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getIdFromArrayStateByType(type: LoadingStates) {
    return this.States.findIndex((x) => x.state == type);
  }
}
