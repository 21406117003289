import {Component, OnInit} from '@angular/core';
import {ExpertsService} from "../../services/experts.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-viewed-experts',
  templateUrl: './viewed-experts.component.html',
  styleUrls: ['./viewed-experts.component.scss']
})
export class ViewedExpertsComponent implements OnInit{
  protected readonly Math = Math;
  experts:any[] = [
  ]

  constructor(
      private expertService:ExpertsService,
      private router: Router

      ) {
  }


  GetViewedExertList(){
    this.expertService.GetExpertListViewed().subscribe((res)=>{
      this.experts = res;
    })

  }

  ngOnInit(): void {
    this.GetViewedExertList();
  }

  NavigateToExpert(id:any){
    this.router.navigate(['/c/experts/expert/'+id]);
  }
}
