import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClientsService } from 'src/app/services/clients.service';
import { AuthService } from './auth.service';

export abstract class AbstractClientInfoService {
  abstract getSpecialization(): Observable<string | undefined>;

  abstract saveComment(clientId: number, comment: string): Observable<unknown>;
}

@Injectable()
export class ClientInfoService extends AbstractClientInfoService {
  private readonly clientService = inject(ClientsService);
  private readonly authService = inject(AuthService);

  getSpecialization() {
    return of(this.authService.User.mainForm.specialization);
  }

  getClient(id: number) {
    return this.clientService.GetClientInfo(id);
  }

  getClientSessionsList(id: number, isClosed = false) {
    return this.clientService.GetClientSessions(id, isClosed);
  }

  override saveComment(clientId: number, comment: string): Observable<unknown> {
    return this.clientService.SetComment({
      clientId,
      comment,
    });
  }
}
