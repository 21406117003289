import {NgModule, Component, inject, Injectable} from '@angular/core';
import {ActivatedRoute, Resolve, Router, RouterModule, Routes} from '@angular/router';
import {MainComponent} from './features/main/main.component';
import {LandingComponent} from './features/main/landing/landing.component';
import {ExpertsComponent} from './features/main/experts/experts.component';
import {AboutComponent} from './features/main/about/about.component';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './services/auth.service';
import {UserModel, UserRole} from './models/user.model';
import {EventsService} from './services/events.service';
import {Observable, catchError, map, of, startWith, tap} from 'rxjs';
import {FaqComponent} from './features/main/faq/faq.component';
import {PublicExpertComponent} from "./features/main/public-expert/public-expert.component";
import {VacancyComponent} from "./features/main/vacancy/vacancy.component";
import {ContactsComponent} from "./features/main/contacts/contacts.component";

const notAuthGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean => {
  const authService = inject(AuthService);
  return !authService.IsLogined;
};

const roleGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.getUser().then(result => {
    console.log("==== roleGuard", result);
    const currentRole = authService.User.role;
    const needRole: UserRole = route.data['role']
    const hasPermission = currentRole == needRole;
    if (!hasPermission) {
      router.navigate([authService.getMainRolePath()]);
    }
    return hasPermission;
  });

};

export const canDeactivateQuestions = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const eventService = inject(EventsService);
  eventService.resetAllEvents()
  const canDeactivate = !authService.IsLogined || authService.User.mainForm != null
  if (!canDeactivate) {
    eventService.throwError("Cначала необходимо заполнить анкету")
  }
  return canDeactivate;
};

export const canActivateProfileOrRedirect = (route: ActivatedRoute, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const hasProfile = authService.IsLogined && authService.User.mainForm != null
  if (!hasProfile) {
    router.navigate([authService.getMainRolePath(), 'questions'], {relativeTo: route});
  }
  return hasProfile;
};

export const canActivateQuestionsOrRedirect = (route: ActivatedRoute, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const hasProfile = authService.IsLogined && authService.User.mainForm != null
  if (hasProfile) {
    router.navigate([authService.getMainRolePath()]);
  }
  return !hasProfile;
};


const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'landing'},
      {path: 'landing', pathMatch: 'full', component: LandingComponent},
      {path: 'experts', pathMatch: 'full', component: ExpertsComponent},
      {path: 'about', pathMatch: 'full', component: AboutComponent},
      {path: 'support', pathMatch: 'full', component: FaqComponent},
      {path: 'vacancy', pathMatch: 'full', component: VacancyComponent},
      {path: 'contacts', pathMatch: 'full', component: ContactsComponent},
      {path: 'expert/:id', pathMatch: 'full', component: PublicExpertComponent},
      {
        path: 'auth',
        canActivate: [notAuthGuard],
        loadChildren: async () => {
          const m = await import("./features/auth-module/auth.module");
          return m.AuthModule;
        }
      },
      {
        path: 'e',
        canActivate: [roleGuard],
        data: {role: 'EXPERT'},
        loadChildren: () => import('./features/expert-module/expert.module').then(m => m.ExpertModule)
      },
      {
        path: 'c',
        canActivate: [roleGuard],
        data: {role: 'CLIENT'},
        loadChildren: () => import('./features/client-module/client.module').then(m => m.ClientModule)
      },
    ],
  },

  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
