import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Swiper} from "swiper";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit,AfterViewInit{
  @ViewChild('swiper') swiperEl: any;


  @Input() SpaceBetween = 0;
  @Input() SliderPerView = 1;
  ArrayCountOfSlide = new Array(0);
  currentIndex: number = 0;


  nextSlide(event:any){
    this.swiperEl.nativeElement.swiper.slideNext();
  }

  prevSlide(event:any){
    this.swiperEl.nativeElement.swiper.slidePrev();
  }

  GoToSlide(idx:number){
    this.swiperEl.nativeElement.swiper.slideTo(idx);
  }



  constructor(private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
  }

  protected readonly console = console;


  ngAfterViewInit(): void {
    this.InitSwiperConfig();
    this.swiperEl.nativeElement.addEventListener('slidechange', (event:any) => {
      this.currentIndex = event.detail[0].activeIndex;
    })
  }

  InitSwiperConfig(){
    this.swiperEl.nativeElement.setAttribute('slides-per-view', this.SliderPerView);
    this.swiperEl.nativeElement.setAttribute('space-between', this.SpaceBetween);
    this.swiperEl.nativeElement.setAttribute('auto-height', true);
    this.ArrayCountOfSlide = new Array(this.swiperEl.nativeElement.children.length - this.SliderPerView + 1);
    this.cdr.detectChanges();
  }
}
