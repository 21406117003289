<ng-template #headerRef>
  <h5 class="expert-info-modal__title modal-title">Изменить пароль</h5>
</ng-template>
<app-modal [header]="headerRef" (destroy)="dismiss()">
  <div [formGroup]="form" modal-content class="expert-info-modal__content">
    <div class="input-wrap">
      <label class="input-label">Старый пароль</label>
      <input
              formControlName="currentPassword"
              class="input-custom" type="text" placeholder="Старый пароль">
      <div class="error-text" *ngIf="form.get('currentPassword').touched && (form.get('currentPassword').hasError('maxlength') || form.get('currentPassword').hasError('minlength'))">Пароль должен быть от 8 до 32 символов.</div>
    </div>
    <div class="input-wrap">
      <label class="input-label">Новый пароль</label>
      <input
              formControlName="password"
              class="input-custom" type="text" placeholder="Новый пароль">
      <div class="error-text" *ngIf="form.get('password').touched && (form.get('password').hasError('maxlength') || form.get('password').hasError('minlength'))">Пароль должен быть от 8 до 32 символов.</div>
    </div>

    <div class="input-wrap">
      <label class="input-label">Повторите пароль</label>
      <input
              formControlName="passwordConfirmation"
              class="input-custom" type="text" placeholder="Повторите пароль">
      <div class="error-text" *ngIf="form.get('password').touched && (form.get('passwordConfirmation').hasError('maxlength') || form.get('passwordConfirmation').hasError('minlength'))">Пароль должен быть от 8 до 32 символов.</div>
      <div class="error-text"*ngIf="
      (form.get('password').touched && form.get('passwordConfirmation').touched)
      &&
      form.get('passwordConfirmation').hasError('mismatch')
       &&
       !(form.get('passwordConfirmation').hasError('maxlength') || form.get('passwordConfirmation').hasError('minlength'))"
      >Пароли не совпадают.</div>
    </div>
    <app-preloader *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.CHANGE_PASSWORD
                  )
                ].value;else btn">
    </app-preloader>
    <ng-template #btn>
      <button (click)="Send()" class="modal-submit btn-custom btn-approve btn-orange">Сохранить</button>
    </ng-template>
  </div>
</app-modal>
