import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../environments/environment";

@Pipe({
    name: 'imagePipe',
})
export class imagePipe implements PipeTransform {

    transform(
        value: string|null
    ): string{
        return value?`${environment.apiUrl}`+value:'';
    }
}
