import { Component } from '@angular/core';
import {Modal} from "../../_modal/modal-options";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-cancel-the-consultation-modal',
  templateUrl: './cancel-the-consultation-modal.component.html',
  styleUrls: ['./cancel-the-consultation-modal.component.scss']
})
export class CancelTheConsultationModalComponent extends Modal {


  constructor(
      public loadingService: LoadingService
  ) {
    super();
  }
  onInjectInputs(inputs: any): void {
  }
}
