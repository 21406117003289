import { Observable, OperatorFunction, filter } from 'rxjs';

export function exists<T>(): OperatorFunction<T, Exclude<T, null | undefined>> {
  return (source: Observable<T>) =>
    source.pipe(
      filter(
        (value): value is Exclude<T, null | undefined> =>
          value !== null && value !== undefined
      )
    );
}
