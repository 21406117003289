<app-crumps-nav [breadcrumbs]="breadcrumbs"></app-crumps-nav>
<div class="container_account">
  <section class="experts" id="experts">
    <div class="experts__container container">
      <h2 class="experts__title title-second">
        Фен-шуй
      </h2>
      <div class="experts__caption title-second__caption">
        <p>
          Значимость этих проблем настолько очевидна, что постоянное информационно-пропагандистское обеспечение нашей
          деятельности прекрасно подходит для реализации вывода текущих активов.
        </p>
      </div>
      <app-preloader *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.GET_EXPERTS
                  )
                ].value;else list_experts">
      </app-preloader>
      <ng-template #list_experts>
        <ul class="experts__list">
        <li class="experts__item" *ngFor="let expert of ExertsList">
          <app-expert-card
                  [isFromBack]="true"
                  [Id]="expert['id']"
                  [imageUrl]="expert.avatarUrl"
                  [name]="expert.name"
                  [level]="expert.level"
                  [profession]="expert.specialization"
                  [rating]="Math.round(expert.avgRating)"
                  [avgRating]="expert.avgRating"
                  [description]="expert.additionalInfo"
                  (OnChoose)="ChooseExpert($event)"
          >
          </app-expert-card>
        </li>
      </ul>
      </ng-template>
      <button
              [routerLink]="'/auth'"
              class="experts__btn btn-custom btn-approve btn-orange">Показать еще</button>
    </div>
  </section>
  <section class="how-works-slider" id="how-works-slider">
    <div class="how-works-slider__container container">
      <h2 class="how-works__title title-second title-second_center">
        Как работает сервис?
      </h2>
      <app-how-works-slider></app-how-works-slider>
    </div>
  </section>

  <app-reviews class="reviews"></app-reviews>

  <app-advantages class="advantages"></app-advantages>
</div>

