import {Injectable} from "@angular/core";
import {MainService} from "./main.service";
import {LoadingService} from "./loading.service";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ExpertsService {

  constructor(
    private mainService: MainService,
    private loadingService: LoadingService,
  ) {
  }

  GetExpertListPublic(tariffId: number | null = null, page: number = 0, limit: number = 8) {
    let query = tariffId ? `?tariffId=${tariffId}&` : '?';
    query += page ? `page=${page}&` : '';
    query += limit ? `limit=${limit}` : '';
    return this.mainService.SendGet(`/public/experts` + query, this.loadingService.StatesNames.GET_EXPERTS);

  }

  GetExpertListViewed(page: number = 0, limit: number = 4) {
    let query = '?';
    query += page ? `page=${page}&` : '';
    query += limit ? `limit=${limit}` : '';
    return this.mainService.SendGet(`/client/expert-history` + query)
      .pipe(
        map((res) => {
          return res['content'];
        }),
        tap(() => {
          console.log("GetExpertListViewed")

        })
      )
  }

  GetExpertInfo(id: number) {
    return this.mainService.SendGet(`/client/expert-info/${id}`);
  }

  GetExpertSheduleByClient(expertId: number) {
    return this.mainService.SendGet(`/client/session/expert-schedule/${expertId}`);
  }

  SetExpertSheduleByClient(sheduleId: number) {
    return this.mainService.SendPost(`/client/session/create-session/${sheduleId}`, {});
  }

  GetExpertShedule(passed = false) {
    return this.mainService.SendGet(`/expert/session/schedule?passed=${passed}`);
  }

  SetExpertSheduleItem(dateTimeInISOString: string, available: boolean = true) {
    return this.mainService.SendPatch(`/expert/session/update-status`, {
        "timestamp": dateTimeInISOString,
        available
      }
    );
  }

  GetConsultingList(query: string, loading = this.loadingService.StatesNames.GET_CIENTS_LIST) {
    return this.mainService.SendGet('/expert/sessions' + query, loading)

  }

  GetStatistics(page: number, loading = this.loadingService.StatesNames.GET_STATISTICS) {
    return this.mainService.SendGet(`/expert/statistic?page=${page}`, loading)

  }

  GetCountStatistics() {
    return this.mainService.SendGet('/expert/total-statistic', this.loadingService.StatesNames.GET_STATISTICS_TOTAL)
  }

  GetSphereList() {
    return this.mainService.SendGet('/user/life-questions-list', this.loadingService.StatesNames.GET_LIFE_QUESTIONS)
  }

  GetRecommendService(lifeQuestionId: any, tariffId: number, limit: number = 6) {
    return this.mainService.SendGet(`/client/random-experts?limit=${limit}&lifeQuestionId=${lifeQuestionId}&tariffId=${tariffId}`, this.loadingService.StatesNames.GET_EXPERTS)
  }


  PostSendEmail(data: any) {
    return this.mainService.SendUnauthenticatedPost('/public/send-email', data, this.loadingService.StatesNames.POST_SEND_EMAIL)
  }
}
