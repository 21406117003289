import {Component, OnInit, ViewChild} from '@angular/core';
import {ExpertsService} from "../../../services/experts.service";
import {map, Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {LoadingService} from "../../../services/loading.service";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  ExpertsList? = [];

  constructor(
      private expertsService: ExpertsService,
      private authService:AuthService,
      private router: Router,
      public loadingService: LoadingService

      ) {
  }

  ngOnInit(): void {
    this.GetExpertsList().subscribe();
  }

  GetExpertsList() {
     return this.expertsService.GetExpertListPublic()
        .pipe(tap((res:any)=>this.ExpertsList = res['content']));

  }

  icons = [
    {
      icon: '../../../../assets/img/icons/love.svg',
      text: 'Гармонизация тела, снятие стресса'
    },
    {
      icon: '../../../../assets/img/icons/home.svg',
      text: 'Гармонизация пространства'
    },
    {
      icon: '../../../../assets/img/icons/love.svg',
      text: 'Любовь и отношения'
    },
    {
      icon: '../../../../assets/img/icons/love_cards.svg',
      text: 'Семья и дети'
    },
    {
      icon: '../../../../assets/img/icons/people.svg',
      text: 'Подбор благоприятных дат'
    },
    {
      icon: '../../../../assets/img/icons/calendar.svg',
      text: 'Работа и Бизнес'
    },
    {
      icon: '../../../../assets/img/icons/computer.svg',
      text: 'Деньги, земля и недвижимость'
    },
    {
      icon: '../../../../assets/img/icons/coin-dollar.svg',
      text: 'Здоровье'
    },
    {
      icon: '../../../../assets/img/icons/road_sign.svg',
      text: 'Поиск пути, саморазвитие'
    },
  ]

  experts = [
  ]
  protected readonly Math = Math;

  slides = [
    {
      text: 'Консультации проверенных экспертов',
      buttonText: 'Подобрать эксперта',
      backgroundColor: '#1d3595' //1D3595
    },
    {
      text: 'Консультации проверенных экспертов',
      buttonText: 'Подобрать эксперта',
      backgroundColor: '#2BC42B'
    },
    {
      text: 'Консультации проверенных экспертов',
      buttonText: 'Подобрать эксперта',
      backgroundColor: '#00ADFE'
    },
  ];

  expertsAdvantage = [
    {
      imageUrl: '../../../../assets/img/AnnaPavlova.jpg',
      name: 'Анна Павлова',
      description: "Вся информация строго конфиденциальна и не выходит за пределы общения с экспертом"
    },
    {
      imageUrl: '../../../../assets/img/AnnaPavlova.jpg',
      name: 'Анна Павлова',
      description: "Вся информация строго конфиденциальна и не выходит за пределы общения с экспертом"
    },
    {
      imageUrl: '../../../../assets/img/AnnaPavlova.jpg',
      name: 'Анна Павлова',
      description: "Вся информация строго конфиденциальна и не выходит за пределы общения с экспертом"
    },
    {
      imageUrl: '../../../../assets/img/AnnaPavlova.jpg',
      name: 'Анна Павлова',
      description: "Вся информация строго конфиденциальна и не выходит за пределы общения с экспертом"
    },
  ];

  ChooseExpert(id:number){
    if(this.authService.IsLogined){
      this.router.navigate(['/c/experts/expert/'+id])
    } else{
      /*this.authService.SetChooseExpert(id);
      this.router.navigate(['/auth']);*/
      this.router.navigate(['/expert/'+id])
    }
  }
}
