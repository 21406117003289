<section class="viewed-experts" id="viewed-experts">
  <div class="viewed-experts__container container">
    <h2 class="viewed-experts__title title-second">
      Просмотренные эксперты
    </h2>
    <ul class="viewed-experts__list">
      <li class="viewed-experts__item" *ngFor="let expert of experts">
        <app-expert-card
          [isFromBack]="true"
          [imageUrl]="expert.avatarUrl"
          [name]="expert.name"
          [level]="expert.level"
          [profession]="expert.specialization"
          [rating]="Math.round(expert.avgRating||0)"
          [avgRating]="expert.avgRating||0"
          [description]="expert.additionalInfo"
          [Id]="expert.id"
          [hideRating]="true"
          (OnChoose)="NavigateToExpert($event)"
        >
        </app-expert-card>
      </li>
    </ul>
  </div>
</section>
