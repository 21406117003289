import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  breadcrumbs: Array<{ text: string, link: string }> = [
    {
      text: 'Главная',
      link: '/landing'
    },
    {
      text: 'Контакты',
      link: '/contacts'
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
