import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { TokenModel } from '../models/token.model';
import { AuthService } from './auth.service';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();
  private readonly authService = inject(AuthService);

  private readonly unreadCounterSubject$ = new BehaviorSubject<number>(0);

  get unreadCounter$() {
    return this.unreadCounterSubject$.asObservable();
  }

  private socket: WebSocketSubject<unknown> | null = null;

  private readonly socketUrl = environment.wssNotifications;

  private readonly mainService = inject(MainService);
  // private readonly loadingService = inject(LoadingService);

  ngOnDestroy(): void {
    if (this.socket) {
      this.socket.complete();
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.unreadCounterSubject$.complete();
  }

  /**
   * creates a socket instance and return socket observable
   * @returns socket observable
   */
  listen() {
    if (this.socket === null) {
      this.unreadCounterSubject$.next(this.authService.User.unreadNotification);

      const token = new TokenModel().getFromStorage().token;

      this.socket = webSocket({
        url: this.socketUrl,
        openObserver: {
          next: () => {
            this.sentSocket({ jwt: token });
          },
        },
      });

      this.socket
        .asObservable()
        .pipe(
          takeUntil(this.destroy$),
          filter((v) => {
            if (typeof v === 'object' && v !== null && 'authorized' in v) {
              return false;
            }

            return true;
          })
        )
        .subscribe({
          next: () => {
            this.incCounter();
          },
        });
    }
  }

  disconnect() {
    this.socket?.complete();
    this.socket = null;
    this.destroy$.next();
    this.resetCounter();
  }

  resetCounter() {
    this.unreadCounterSubject$.next(0);
  }

  private incCounter() {
    this.unreadCounterSubject$.next(this.unreadCounterSubject$.value + 1);
  }

  private sentSocket(payload: unknown) {
    if (this.socket === null) {
      return;
    }
    this.socket.next(payload);
  }

  HideNotifications(id: number) {
    return this.mainService.SendPatch(`/user/notifications/hide/${id}`, {});
  }

  GetNotificationList(page: number, limit: number) {
    return this.mainService.SendGet(
      `/user/notifications?page=${page}&limit=${limit}`
    );
  }

  GetNotificationsWithFilter() {
    return this.mainService.SendGet(
      `/user/notifications?shownOnly=true&notificationType=SESSION_MOVED&&notificationType=SESSION_CANCELLED`
    );
  }
}
