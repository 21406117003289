<section class="advantages" id="advantages">
  <div class="advantages__container container">
    <h2 class="advantages__title title-second">
      Наши преимущества
    </h2>
    <div class="advantages__row">
      <div class="advantages__col">
        <div class="advantages-card">
          <img class="advantages-card__img advantages-card__img_left" src='../../../../assets/img/squiggle_grey.svg'
               alt="">
          <img class="advantages-card__img advantages-card__img_right" src='../../../../assets/img/squiggle_grey.svg'
               alt="">
          <img class="advantages-card__img advantages-card__img_bottom" src='../../../../assets/img/squiggle_grey.svg'
               alt="">
          <div class="advantages-card__card advantages-card__card_pending">
            <div class="advantages-card__head">
              <span>
                Мои услуги
              </span>
              <span>
                17 окт, пн, 10:00
              </span>
            </div>
            <h4 class="advantages-card__title">
              Консультация эксперта
            </h4>
            <div class="advantages-card__text">
              Эксперт: Виктория Романова
            </div>
            <div class="advantages-card__status advantages-card__status_pending">
              Ожидает встречи
            </div>
          </div>
          <div class="advantages-card__card advantages-card__card_completed">
            <div class="advantages-card__head">
              <span>
                Мои услуги
              </span>
              <span>
                08 окт, ср, 10:00
              </span>
            </div>
            <h4 class="advantages-card__title">
              Консультация нумеролога
            </h4>
            <div class="advantages-card__text">
              Эксперт: Анна Кострова
            </div>
            <div class="advantages-card__status advantages-card__status_completed">
              Завершено
            </div>
          </div>
          <div class="advantages-card__card advantages-card__card_canceled">
            <div class="advantages-card__head">
              <span>
                Мои услуги
              </span>
              <span>
                02 авг, вт, 18:00
              </span>
            </div>
            <h4 class="advantages-card__title">
              Консультация эксперта
            </h4>
            <div class="advantages-card__text">
              Эксперт: Татьяна Шишкина
            </div>
            <div class="advantages-card__status advantages-card__status_canceled">
              Отменено
            </div>
          </div>
        </div>
      </div>
      <div class="advantages__col">
        <ul class="advantages__list">
          <li class="advantages__item">
            <div class="advantages__content">
              <div class="advantages__icon">
                <img class="advantages__svg" src='../../../../assets/img/icons/Star%202.svg' alt="">
              </div>
              <h4 class="advantages__subtitle">
                Главное преимущество
              </h4>
              <div class="advantages__text">
                <p>
                  Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих
                  профессионалов. Это основное и самое главное преимущество ДЗЕНик
                </p>
              </div>
            </div>
          </li>
          <li class="advantages__item">
            <div class="advantages__content">
              <div class="advantages__icon">
                <img class="advantages__svg" src='../../../../assets/img/icons/users%2001.svg' alt="">
              </div>
              <h4 class="advantages__subtitle">
                Проверенные мастера
              </h4>
              <div class="advantages__text">
                <p>
                  Размещаем на сайте только тех мастеров, которые закончили хорошие школы, прошли экзамены, получили
                  сертификат и несмотря на свой сертификат, проходят отбор на ДЗЕНике.
                </p>
              </div>
            </div>
          </li>
          <li class="advantages__item">
            <div class="advantages__content">
              <div class="advantages__icon">
                <img class="advantages__svg" src='../../../../assets/img/icons/chat%20love.svg' alt="">
              </div>
              <h4 class="advantages__subtitle">
                Отзывы и оценки клиентов
              </h4>
              <div class="advantages__text">
                <p>
                  Помогаем выбрать нужного специалиста, подходящего лучше всего под конкретный запрос. Каждый эксперт
                  проходит тестирование и подписывает кодекс правил ДЗЕНики.
                </p>
              </div>
            </div>
          </li>
          <li class="advantages__item">
            <div class="advantages__content">
              <div class="advantages__icon">
                <img class="advantages__svg" src='../../../../assets/img/icons/lock.svg' alt="">
              </div>
              <h4 class="advantages__subtitle">
                Надежность платформы
              </h4>
              <div class="advantages__text">
                <p>
                  Вся информация строго конфиденциальна и не выходит за пределы общения с экспертом.
                </p>
              </div>
            </div>
          </li>
        </ul>
        <a [routerLink]="'../about'" class="advantages__link">
          Подробнее о сервисе
        </a>
      </div>
    </div>
  </div>
</section>
