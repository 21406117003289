import { ComponentRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ModalContainerComponent } from './modal-container.component';

export class ModalRef {
  private result$ = new Subject<any>();

  constructor(
    private modalContainer: ComponentRef<ModalContainerComponent>,
    private modal: ComponentRef<Modal> // private renderer: Renderer2,
  ) {
    this.modal.instance.modalInstance = this;
  }

  close(output: any): void {
    this.result$.next(output);
    this.destroy$();
  }

  dismiss(output: any): void {
    this.result$.error(output);
    this.destroy$();
  }

  send(output: any): void {
    this.result$.next(output);
  }

  injectInputs(inputs: any): void {
    this.modal.instance.onInjectInputs(inputs);
  }

  onResult(): Observable<any> {
    return this.result$.asObservable();
  }

  private destroy$(): void {
    this.modal.destroy();
    this.modalContainer.destroy();
    this.result$.complete();
  }
}

export abstract class Modal {
  modalInstance!: ModalRef;

  abstract onInjectInputs(inputs: any): void;

  close(output?: any): void {
    this.modalInstance.close(output);
  }

  send(output?: any): void {
    this.modalInstance.send(output);
  }

  dismiss(output: any = false): void {
    this.modalInstance.dismiss(output);
  }
}
