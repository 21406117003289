import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';

@Component({
  selector: 'app-crumps-nav',
  templateUrl: './crumps-nav.component.html',
  styleUrls: ['./crumps-nav.component.scss']
})

export class CrumpsNavComponent implements OnInit {
  @Input() breadcrumbs: Array<{ text: string, link: string }> = [
    { text: 'Главная', link: '/' },
    { text: 'Авторизация', link: '/auth' }
  ];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    
    // this.route.url.subscribe(segments => {
    //   let path = '';
    //   const dynamicBreadcrumbs = segments.map((segment: UrlSegment) => {
    //     path += `/${segment.path}`;
    //     let name = '';
    //     switch (segment.path) {
    //       case 'login':
    //         name = 'Авторизация';
    //         break;
    //       case 'client':
    //         name = 'Клиент';
    //         break;
    //       case 'register':
    //         name = 'Регистрация';
    //         break;
    //       case 'recover':
    //         name = 'Восстановление пароля';
    //         break;
    //       // ... другие возможные пути и их имена ...
    //     }

    //     return { text: name, link: path };
    //   });

    //   this.breadcrumbs = [...this.breadcrumbs, ...dynamicBreadcrumbs];
    // });
  }
}
