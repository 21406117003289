<div class="card">
  <div class="card__img" >
    <img
      dz-img
      [skipPrefix]="!isFromBack"
      [src]="imageUrl"
      alt="Expert Image"
      class="object-fit"
    />
  </div>
  <div class="card-header">
    <h5 class="card-header__name">{{ name }}</h5>
    <b class="card-header__level" *ngIf="visibleProf"
      ><span>{{ level }} уровень</span></b
    >
  </div>
  <small class="card__profession" *ngIf="visibleProf">{{ profession }}</small>
  <div class="card-rating" *ngIf="visibleProf && !hideRating">
    <ng-container *ngIf="rating">
      <img
        class="card-rating__svg card-rating__svg_active"
        *ngFor="let i of Array.from(Array(Math.round(rating)).keys())"
        src="./../../../assets/img/star.svg"
      />
    </ng-container>
    <img
      class="card-rating__svg"
      *ngFor="let i of Array.from(Array(Math.round(5 - rating)).keys())"
      src="./../../../assets/img/empty_star.svg"
    />
    <span class="card-rating__number">{{ avgRating | number: '1.0-2':'en'}}</span>
  </div>
  <div class="card__text">
    <p>
      {{ description }}
    </p>
  </div>
  <button
    class="card__btn btn-custom btn-cancel"
    (click)="ChooseExpert()"
    *ngIf="visibleProf"
  >
    Выбрать
  </button>
</div>
