import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class ErrorInterceptor implements HttpInterceptor {

    private static accessTokenError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private httpClient: HttpClient) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // console.log("request",request.url);
            // console.log("Ошибка",ErrorInterceptor.accessTokenError$.getValue());
            if (err.status === HttpStatusCode.Unauthorized && err.error.message === "Invalid token") {
                if (!ErrorInterceptor.accessTokenError$.getValue()) {
                    ErrorInterceptor.accessTokenError$.next(true);
                    // console.log("next step");
                    const body = {
                        refreshToken: localStorage.getItem('refresh_token'),
                    };

                    localStorage.removeItem('access_token')
                    const url = environment.apiUrl + '/v1/user/refresh';
                    // console.log("Перед рефрешем")
                    return this.httpClient.post(url, body).pipe(
                        tap(()=>{console.log("tap refresh")}),
                        switchMap((event: any) => {
                            localStorage.setItem('token', JSON.stringify(event));
                            localStorage.setItem('access_token', event.token);
                            localStorage.setItem('refresh_token', event.refreshToken);

                            ErrorInterceptor.accessTokenError$.next(false);
                            const newRequest = request.clone({
                                setHeaders: {
                                    Authorization: `${localStorage.getItem('access_token')}`
                                }
                            });
                            // console.log("refresh",request)
                            return next.handle(newRequest);
                        }),
                        catchError(er => {
                            localStorage.clear();
                            location.reload();
                            return throwError(er);
                        })
                    );
                } else {
                    return this.waitNewTokens().pipe(
                        switchMap((event: any) => {
                            const newRequest = request.clone({
                                setHeaders: {
                                    Authorization: `${localStorage.getItem('access_token')}`
                                }
                            });
                            // console.log("waitNewTokens",request)
                            return next.handle(newRequest);
                        })
                    );
                }
            } else if (err.status === 403) {
                localStorage.clear();
                location.reload();
            }

            // const error = err.error.message || err.statusText;
            // console.log("error");
            return throwError(err);
        }));
    }
    private waitNewTokens(): Observable<any> {
        // console.log("waitNewTokens function");
        const subject = new Subject<any>();
        const waitToken$: Subscription = ErrorInterceptor.accessTokenError$.subscribe((error: boolean) => {
            // console.log("subscribe",error)
            if(!error) {
                subject.next(true);
                waitToken$.unsubscribe();
            }
        });
        return subject.asObservable();
    }
}
