import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientInfo } from '../models/client.model';
import { LoadingService } from './loading.service';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(
    private mainService: MainService,
    private loadingService: LoadingService
  ) {}

  GetClientInfo(id: number) {
    return this.mainService.SendGet(
      `/expert/client-info/${id}`
    ) as Observable<ClientInfo>;
  }

  GetClientSessions(
    id: number,
    isClosed: boolean,
    loading = this.loadingService.StatesNames.GET_SESSIONS_OF_CLIENT
  ) {
    return this.mainService.SendGet(
      `/expert/client-sessions/${id}?isClosed=${isClosed}`,
      loading
    ) as Observable<
      {
        sessionId: number;
        dateTime: string;
      }[]
    >;
  }

  SetComment(data: { clientId: number; comment: string }) {
    return this.mainService.SendPost(
      '/expert/comment-client',
      data,
      this.loadingService.StatesNames.SET_COMMENT
    );
  }

  GetClientConsultations() {
    return this.mainService.SendGet(
      '/client/session',
      this.loadingService.StatesNames.GET_SESSIONS_OF_CLIENT
    );
  }

  SetCommentAndRatingOfSession(sessionId: number, data: any) {
    return this.mainService.SendPatch(
      `/client/session/comment-session/${sessionId}`,
      data,
      this.loadingService.StatesNames.CHANGE_SESSION
    );
  }

  CancelSession(sessionId: number) {
    return this.mainService.SendPatch(
      `/client/session/cancel-session/${sessionId}`,
      {},
      this.loadingService.StatesNames.CHANGE_SESSION
    );
  }

  MoveSession(sessionId: number, scheduleId: number) {
    return this.mainService.SendPatch(
      `/client/session/move-session/${sessionId}/${scheduleId}`,
      {},
      this.loadingService.StatesNames.MOVE_SESSION
    );
  }
}
