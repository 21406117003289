import { Injectable } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import { saveAs } from 'file-saver'
import {of} from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DownloadService {
    constructor(private sanitizer: DomSanitizer ) {}

    saveResponse2Img = (response: any) => {
        const type = response.headers.get("Content-Type");
        const blob = new Blob([response.body], { type });
        let imageURL = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(blob)
        );
        return of(imageURL);
    };

    getResponse2File = (response: any) => {
        const type = response.headers.get("Content-Type");
        const blob = new Blob([response.body], { type });
        const filename = this.parseContentDisposition(
            response.headers.get("Content-Disposition")
        );

        return URL.createObjectURL(blob); 
    };

    saveResponse2File = (response: any) => {
        const type = response.headers.get("Content-Type");
        const blob = new Blob([response.body], { type });

        const filename = this.parseContentDisposition(
            response.headers.get("Content-Disposition")
        );

        saveAs(blob, filename);
    };

    getAndSaveResponse2File = (response: any) => {
        const type = response.headers.get("Content-Type");
        const blob = new Blob([response.body], { type });

        const filename = this.parseContentDisposition(
            response.headers.get("Content-Disposition")
        );

        saveAs(blob, filename);

        return URL.createObjectURL(blob);
    };



    private parseContentDisposition = (disposition: string) => {
        if (disposition && disposition.indexOf("attachment") !== -1) {
            const matches = disposition.split('filename=')[1].split(';')[0].split(",")[0];
            if (matches != null){
                return decodeURIComponent(matches);
            }
        }
        return "";
    };
}
