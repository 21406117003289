import { Injectable } from "@angular/core";
import {Location} from "@angular/common";
import { MainService } from "./main.service";
import { ActivatedRoute, Router } from "@angular/router";

import {BehaviorSubject, Observable, Subject, of, throwError, EMPTY} from "rxjs";
import { catchError, delay, filter, map, tap } from "rxjs/operators";

import { TokenModel } from "../models/token.model";
import { LoginDTO } from "../models/dto/login-in.dto";
import { UserModel, UserRole } from "../models/user.model";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { LoadingService } from "./loading.service";
import {EventsService} from "./events.service";
import {RegisterOutDto} from "../models/dto/register-out.dto";

@Injectable({
    providedIn: "root",
})
export class AuthService {

    User: UserModel = new UserModel();

    IsLoginedChange$ = new Subject<boolean>();
    IsLogined = false;
    UpdateUserForm$ = new Subject();

    ChooseIdExpert:number|null = null;

    constructor(private mainService: MainService, private router: Router, private loadingService: LoadingService,private eventsService: EventsService,readonly location: Location) {
        this.IsLoginedChange$.subscribe((val) => {
            this.IsLogined = val!;

            const loginedAndInAuth = this.IsLogined && this.location.path().startsWith('/auth')
            const hasNotProfile = this.IsLogined  && this.User.mainForm == null
            const hasDiffPath =
                this.IsLogined && (
                    (this.User.role == 'EXPERT' && this.location.path().startsWith(`/c`)) ||
                    (this.User.role == 'CLIENT' && this.location.path().startsWith(`/e`))
                )

            if (loginedAndInAuth || hasNotProfile || hasDiffPath) {
                console.log("NAVIGATE !!!! ")
                this.navigateToMainRole()
            }
        });
    }

    InitExpert(){
        const value = localStorage.getItem('expertId');
        if(value){
            this.SetChooseExpert(value)
        }
    }

    GetExpert(){
        return this.ChooseIdExpert;
    }

    SetChooseExpert(value:any){
        localStorage.setItem('expertId',value);
        this.ChooseIdExpert = value;
        if(value === null){
            localStorage.removeItem('expertId');
        }
    }

    Login(payload: LoginDTO): Observable<TokenModel> {


        return this.mainService.Login(payload).pipe(
            tap(
                (res: TokenModel) => {

                    let token = new TokenModel().deserialize(res);

                    localStorage.setItem('access_token', res.token);
                    localStorage.setItem('refresh_token', res.refreshToken);
                    // localStorage.setItem('email', email);

                    token.saveToStorage()
                    this.CheckToken()
                }
            )
        );
    }

    CheckToken(onComplitedFunction?: Function) {
      console.log("CheckToken")
        if(this.mainService.CheckToken()) {
            this.GetMe().pipe(tap(()=>{
                this.InitExpert();
                if(onComplitedFunction){
                    onComplitedFunction()
                }
            })).subscribe()
        } else {
            this.IsLoginedChange$.next(false);
            if(onComplitedFunction){
                onComplitedFunction()
            }
        }
    }

    GetMe() {
        return this.mainService.SendGet('/user/me').pipe(
            tap(
                (res: UserModel) => {
                    this.IsLogined = true
                    this.User.deserialize(res)
                    console.log("getme !! ", this.User)
                    this.IsLoginedChange$.next(true);
                }
            ),
             catchError((err: HttpErrorResponse) => of(this.catchUserError(err)))
        );
    }

    getUser(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if(this.mainService.CheckToken()) {
                this.mainService.SendGet('/user/me')
                .pipe(catchError((error: any, caught: any) => {
                        resolve(false);
                        return caught;
                    }))
                .subscribe((_) => {
                    resolve(true);
                });
            } else {
                resolve(false);
            }
        });
    }

    catchUserError(err: HttpErrorResponse, redo?: Function){
        if ((err.status == HttpStatusCode.Unauthorized && err.error.message === "Invalid token")){
            this.RefreshToken()
                .subscribe(()=>{ if (redo) redo();  })
        }
    }

    RefreshToken() {
        const token = new TokenModel();
        const refreshToken = token.deserialize(token.getFromStorage()).refreshToken
        // this.mainService.ClearSession();
        return this.mainService.SendPost('/user/refresh', {refreshToken}).pipe(
            catchError((err: HttpErrorResponse) => of(this.Logout())),
            tap(
                (res: TokenModel) => {
                    let token = new TokenModel().deserialize(res);
                    token.saveToStorage()
                    this.CheckToken()
                }
            )
        );
    }

    Logout() {
        this.ClearSession()
        this.navigateToMainRole()
        return this.mainService.SendPost("/user/logout", {}, this.loadingService.StatesNames.LOGOUT).pipe(
            tap(()=>this.ClearSession())
        );
    }

    ClearSession() {
        new TokenModel().clearStorage()
        this.User = new UserModel();
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        // this.mainService.ClearSession()
        this.IsLoginedChange$.next(false);
    }

    Register(payload: RegisterOutDto) {
        return this.mainService.SendPost('/user/register', payload);
    }

    SendSMS(phone:string,action:string, loading= this.loadingService.StatesNames.SEND_SMS){
        return this.mainService.SendPost('/verify/send',{phone,action}, loading);
    }



    VerifyCode(phone:string,code:string,action:string){
        return this.mainService.SendPost('/verify/verify',{phone,action,code},this.loadingService.StatesNames.VERIFY_CODE)
    }

    ResetPassword(payload:any){
        return this.mainService.SendPatch('/user/reset-password',payload)
            .pipe(
                catchError((err:any)=>{
                    if(err.status === 429){
                        this.eventsService.throwError('Кол-во запросов превышено. Попробуйте позже')
                    }
                    return EMPTY;
                }),
            );

    }

    getMainRolePath() {
        console.log("getMainRolePath",this.User.role)
        if(this.User.role == UserRole.CLIENT) {
            return '/c'
        }
        else if(this.User.role == UserRole.EXPERT) {
            return '/e'
        }
        else  {
            return '/'
        }
    }

    navigateToMainRole() {
        this.router.navigate([this.getMainRolePath()])
    }

    /*addTestProfile() {
        this.User.mainForm = {
            name:         "Vlad",
            email:        "email",
            place:        {
                id:      "id",
                name:    "name",
                coords:  "coords"
            },
            birthday:     new Date(),
            timezone:     1,
            birthdayTime: '22:00'
        }
    }*/


    testChangeTokenToInvalid(){
        // this.mainService.BaseInitByToken('w21dkedek');
        localStorage.setItem('access_token', 'w21dkedek');
    }
    testGetMe(){
        this.GetMe().subscribe()
    }

    EditClientInfo(data:any){
        return this.mainService.SendPatch('/client/me',data, this.loadingService.StatesNames.EDIT_PROFILE)
    }

    EditExpertInfo(data:any){
        return this.mainService.SendPatch('/expert/me',data, this.loadingService.StatesNames.EDIT_PROFILE)
    }

    ChangePassword(payload:any){
        return this.mainService.SendPatch('/user/password-change' ,payload,this.loadingService.StatesNames.CHANGE_PASSWORD)
            .pipe(
                tap(
                    (res: TokenModel) => {
                        let token = new TokenModel().deserialize(res);
                        token.saveToStorage()
                        localStorage.setItem('access_token', res.token);
                        localStorage.setItem('refresh_token', res.refreshToken);
                        this.CheckToken()
                    }
                )
            )

    }

    EditAvatar(payload:any){
        return this.mainService.SendPost('/user/avatar',payload,this.loadingService.StatesNames.EDIT_AVATAR);

    }

    GetExpertEducation(){
        return this.mainService.SendGet('/expert/education',this.loadingService.StatesNames.EDIT_AVATAR)

    }

    EditEducations(payload:any){
        return this.mainService.SendPatch('/expert/education',payload,this.loadingService.StatesNames.EDIT_EDUCATION)
    }

    GetArticles(limit= 4,page=null){
        let query = '?';
        query+= page!==null?`page=${page}&`:'';
        query+= limit!==null?`limit=${limit}`:'';
        return this.mainService.SendGet(`/articles`+query,this.loadingService.StatesNames.GET_ARTICELS)
            .pipe(
                tap(()=>{
                    console.log("GetArticles")
                })
            )
    }

}
