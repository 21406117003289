<section class="main-slider">
  <div class="container main-slider__container">
    <img src="../../../assets/img/MacBookPro.png" alt="macbook" class="macbook">
    <app-slider class="main-slider__slider"
                [SliderPerView]="1"
                [SpaceBetween]="0"
    >
      <swiper-slide *ngFor=" let slide of slides">
        <div class="inner" [style.background-color]="slide.backgroundColor">
          <p>{{ slide.text }}</p>
          <button class="btn-custom btn-approve">{{ slide.buttonText }}</button>
        </div>
      </swiper-slide>
    </app-slider>
  </div>
</section>

<section class="icons__wrapper">
  <div class="icons__container container">
    <h3>Эксперты работают с актуальными сферами жизни</h3>
    <ul class="icons">
      <li *ngFor="let icon of icons">
        <div class="icons__item">
          <img src={{icon.icon}} alt="icon">
        </div>
        <p class='icons__description'>{{icon.text}}</p>
      </li>
      <li>
        <a routerLink="">
          <div class="icons__next">
            <img src='../../../../assets/img/icons/arrow right.svg' alt="icon">
          </div>
          <p class='icons__description'>Выбрать эксперта</p>
        </a>
        <p></p>
      </li>
    </ul>
  </div>
</section>

<section class="experts">
  <div class="experts__experts container">
    <h2 class="experts__title title-second">
      Фен-шуй
    </h2>
    <app-preloader *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.GET_EXPERTS
                  )
                ].value;else list_experts">
    </app-preloader>
    <ng-template #list_experts>
      <ul class='experts__list'>
        <li class="experts__item" *ngFor="let expert of ExpertsList">
          <app-expert-card

                  [imageUrl]="expert['avatarUrl']"
                  [name]="expert['name']"
                  [level]="expert['level']"
                  [profession]="expert['specialization']"
                  [rating]="expert['avgRating']"
                  [Id]="expert['id']"
                  [description]="expert['additionalInfo']"
                  (OnChoose)="ChooseExpert($event)"

          >
          </app-expert-card>
        </li>
      </ul>
    </ng-template>

    <button class="experts__btn btn-custom btn-approve btn-orange">
      Подобрать эксперта
    </button>
  </div>
</section>

<section class="expert-selection" id="expert-selection">
  <div class="expert-selection__container container">
    <div class="expert-selection-advantage">
      <h3 class="expert-selection-advantage__title title-third">
        Мы строго отбираем экспертов<br> из каждой области и работаем с лучшими
      </h3>
      <ul class="expert-selection-advantage__list">
        <li class="expert-selection-advantage__item" *ngFor="let expert of expertsAdvantage">
          <app-expert-card
            [visibleProf]=false
            [isFromBack]="false"
            [imageUrl]="expert.imageUrl"
            [name]="expert.name"
            [description]="expert.description">
          </app-expert-card>
        </li>
      </ul>
    </div>
    <div class="expert-selection-comfort">
      <div class="expert-selection-comfort__col">
        <h2 class="expert-selection-comfort__title title-second">
          Подберем эксперта, с которым вам будет комфортно
        </h2>
        <ul class="expert-selection-comfort__list">
          <li class="expert-selection-comfort__item">
            <div class="expert-selection-comfort__number">
              1
            </div>
            <div class="expert-selection-comfort__texts">
              <h5 class="expert-selection-comfort__subtitle">
                Когда вам удобно?
              </h5>
              <div class="expert-selection-comfort__desc">
                Выбор блока с расписанием
              </div>
            </div>
          </li>
          <li class="expert-selection-comfort__item">
            <div class="expert-selection-comfort__number">
              2
            </div>
            <div class="expert-selection-comfort__texts">
              <h5 class="expert-selection-comfort__subtitle">
                Чтобы вы хотели обсудить?
              </h5>
              <div class="expert-selection-comfort__desc">
                Выбор блока со сферами жизни
              </div>
            </div>
          </li>
          <li class="expert-selection-comfort__item">
            <div class="expert-selection-comfort__number">
              3
            </div>
            <div class="expert-selection-comfort__texts">
              <h5 class="expert-selection-comfort__subtitle">
                Результат поиска
              </h5>
              <div class="expert-selection-comfort__desc">
                Список с подобранными эксператами
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="expert-selection-comfort__col">
        <div class="expert-selection-comfort__img">
          <img src='../../../../assets/img/expert-selection-comfort__img.png'
               alt="">
        </div>
        <!--      <img class="expert-selection-comfort__img" src='../../../../assets/img/expert-selection-comfort__img.png' alt="">-->
      </div>
    </div>
  </div>
</section>

<section class="how-works" id="how-works">
  <div class="how-works__container container">
    <h2 class="how-works__title title-second">
      Как это работает?
    </h2>
    <ul class="how-works__list">
      <li class="how-works__item how-works-first">
        <div class="how-works__row how-works__row_center">
          <div class="how-works__column">
            <ul class="how-works-first__list">
              <li class="how-works-first__item">
                <img class="how-works-first__icon" src='../../../../assets/img/icons/check-circle.svg' alt="icon">
                <b class="how-works-first__text">
                  Ольга Васильева
                </b>
              </li>
              <li class="how-works-first__item">
                <img class="how-works-first__icon" src='../../../../assets/img/icons/check-circle.svg' alt="icon">
                <b class="how-works-first__text">
                  г. Москва
                </b>
              </li>
              <li class="how-works-first__item">
                <img class="how-works-first__icon" src='../../../../assets/img/icons/check-circle.svg' alt="icon">
                <b class="how-works-first__text">
                  Поиск себя и семейные отношения
                </b>
              </li>
            </ul>
          </div>
          <div class="how-works__column how-works-first-text how-works-text">
            <div class="how-works-text__number">
              01.
            </div>
            <div class="how-works-text__texts">
              <div class="how-works-text__subtitle">
                Заполните анкету
              </div>
              <div class="how-works-text__text">
                <p>
                  Предложим системы, которые работают с вашим запросом.
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="how-works__item how-works-second">
        <div class="how-works__row">
          <div class="how-works__column how-works-second-text how-works-text">
            <div class="how-works-text__number">
              02.
            </div>
            <div class="how-works-text__texts">
              <div class="how-works-text__subtitle">
                Выберите эксперта
              </div>
              <div class="how-works-text__text">
                <p>
                  Посмотрите, что эксперты рассказывают о себе и своём опыте в анкете. Выберите того, кто интуитивно
                  вызывает симпатию и доверие.
                </p>
              </div>
            </div>
          </div>
          <div class="how-works__column how-works-second__cards">
            <div class="how-works-second__card how-works-second__card_front">
              <div class="how-works-second__img">
                <img class="object-fit" src='../../../../assets/img/Anna.png' alt="icon">
              </div>
              <div class="how-works-second__text">
                Анна Павлова
                <small class="how-works-second__text_small">
                  2 уровень
                </small>
              </div>
            </div>
            <div class="how-works-second__card how-works-second__card_back">
              <div class="how-works-second__img">
                <img class="object-fit" src='../../../../assets/img/Arkady.png' alt="icon">
              </div>
              <div class="how-works-second__text">
                Аркадий Костров
                <small class="how-works-second__text_small">
                  5 уровень
                </small>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="how-works__item how-works-third">
        <div class="how-works__row">
          <div class="how-works__column">
            <ul class="how-works-third__list">
              <li class="how-works-third__item">
                <div class="how-works-third__text">
                  13 июн, чт
                </div>
              </li>
              <li class="how-works-third__item">
                <div class="how-works-third__text">
                  14 июн, пт
                </div>
              </li>
              <li class="how-works-third__item">
                <div class="how-works-third__text">
                  15 июн, сб
                  <b class="how-works-third__text_bold">
                    14:00
                  </b>
                </div>
              </li>
            </ul>
          </div>
          <div class="how-works__column">
            <div class="how-works__column how-works-third-text how-works-text">
              <div class="how-works-text__number">
                03.
              </div>
              <div class="how-works-text__texts">
                <div class="how-works-text__subtitle">
                  Выберите дату <br>
                  и время консультации
                </div>
                <div class="how-works-text__text">
                  <p>
                    Назначайте и переносите, мы напомним и не дадим вам забыть.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="how-works__item how-works-fourth">
        <div class="how-works__row">
          <div class="how-works__column">
            <div class="how-works__column how-works-fourth-text how-works-text">
              <div class="how-works-text__number">
                04.
              </div>
              <div class="how-works-text__texts">
                <div class="how-works-text__subtitle">
                  Оплатите консультацию
                </div>
                <div class="how-works-text__content">
                  <div class="how-works-text__text">
                    <p>
                      Оплата картами РФ и иностранных банков. Работайте со своими запросами из любой точки мира.
                    </p>
                  </div>
                  <ul class="how-works-text__list">
                    <li class="how-works-text__item">
                      <img class="how-works-text__img" src='../../../../assets/img/mir.png' alt="">
                    </li>
                    <li class="how-works-text__item">
                      <img class="how-works-text__img" src='../../../../assets/img/visa.png' alt="">
                    </li>
                    <li class="how-works-text__item">
                      <img class="how-works-text__img" src='../../../../assets/img/mastercard.png' alt="">
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="how-works__column how-works-fourth__images">
            <div class="how-works-fourth__images">
              <div class="how-works-fourth__img">
                <img class="object-fit"
                     src='../../../../assets/img/person-paying-with-its-smartphone-wallet-app%202.png'
                     alt="">
              </div>
              <img class="how-works-fourth__img_left" src='../../../../assets/img/squiggle.svg' alt="">
              <img class="how-works-fourth__img_right" src='../../../../assets/img/squiggle.svg' alt="">
            </div>
          </div>
        </div>
      </li>
      <li class="how-works__item how-works-fifth">
        <div class="how-works-fifth__row how-works__row">
          <div class="how-works__column how-works-fifth__images">
            <div class="how-works-fifth__images">
              <img class="how-works-fifth__img" src='../../../../assets/img/MacBook%20Pro%2016.png' alt="icon">
              <img class="how-works-fifth__img_left" src='../../../../assets/img/squiggle.svg' alt="">
              <img class="how-works-fifth__img_right" src='../../../../assets/img/squiggle.svg' alt="">
            </div>
          </div>
          <div class="how-works__column">
            <div class="how-works__column how-works-fifth-text how-works-text">
              <div class="how-works-text__number">
                05.
              </div>
              <div class="how-works-text__texts">
                <div class="how-works-text__subtitle">
                  Подключитесь к видео чату<br> к экспертам
                </div>
                <div class="how-works-text__text">
                  <p>
                    На нашей платформе видеоконсультации проходят
                    в защищенном личном кабинете. Конфиденциальные сессии с вашим экспертом доступны из любой точки
                    мира.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</section>

<app-reviews></app-reviews>

<app-advantages class="advantages"></app-advantages>

<section class="questions" id="questions">
  <div class="questions__container container">
    <h2 class="advantages__title title-second title-second_center">Часто задаваемые вопросы</h2>
    <div class="questions-accordion">
      <ul class="questions-accordion__list">
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №1
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №3
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №5
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №7
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
      </ul>
      <ul class="questions-accordion__list">
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №2
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №4
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №6
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
        <li class="questions-accordion__item">
          <div class="questions-accordion__content">
            <input class="questions-accordion__input" type="checkbox" checked>
            <div class="questions-accordion__head">
              <h3 class="questions-accordion__title">
                Текст вопроса №8
              </h3>
              <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                   alt="icon">
            </div>
            <div class="questions-accordion__body">
              <p>
                Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                посмешище, хотя само их существование приносит несомненную пользу обществу.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="socials" id="socials">
  <div class="socials__container container">
    <h2 class="socials__title title-second title-second_center">Остались вопросы?</h2>
    <div class="socials__caption">
      Выберите любой из удобных способов и наши операторы свяжутся с вами в ближайшее время
    </div>
    <ul class="socials__list">
      <li class="socials__item">
        <a href="#" class="socials__link">
          <img class="socials__img" src='../../../../assets/img/telegram.png' alt="icon">
          Telegram
        </a>
      </li>
      <li class="socials__item">
        <a href="#" class="socials__link">
          <img class="socials__img" src='../../../../assets/img/whatsap.png' alt="icon">
          WhatsApp
        </a>
      </li>
      <li class="socials__item">
        <a href="mailto:info@dzenika.ru" class="socials__link">
          <img class="socials__img" src='../../../../assets/img/yandex.png' alt="icon">
          Почта
        </a>
      </li>
    </ul>
  </div>
</section>



