import {
  Component,
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, map, startWith, tap } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from '../../services/auth.service';

@Directive({
  selector: '[hover-class]',
})
export class HoverClassDirective {
  constructor(public elementRef: ElementRef) {}
  @Input('hover-class') hoverClass: any;

  @HostListener('mouseenter') onMouseEnter() {
    console.log('mouseenter');
    this.elementRef.nativeElement.classList.add(this.hoverClass);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.classList.remove(this.hoverClass);
  }
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public readonly authService = inject(AuthService);
  private readonly notificationService = inject(NotificationService);

  protected readonly unreadCounter$ =
    this.notificationService.unreadCounter$.pipe(
      map((v) => (v > 9 ? '9+' : v))
    );

  User = this.authService.User;
  links = this.initLinks();
  subLinks: any[] = this.initSubLinks();

  isShowSubmenu = false;
  isShowSubmenuHover = false;

  private readonly destroyRef = inject(DestroyRef);

  ngOnInit() {
    console.log('[HEADER] Init User = ', this.User);
    this.setHeader();
    this.authService.IsLoginedChange$.pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(0),
      tap(() => {
        this.setHeader();
        this.notificationService.disconnect();
      }),
      filter(() => {
        return this.authService.IsLogined;
      })
    ).subscribe((msg) => {
      this.notificationService.listen();
      console.log(msg);
    });

    this.authService.UpdateUserForm$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.User = this.authService.User;
    });
  }

  setHeader() {
    console.log('[HEADER] update user = ', this.authService.User);
    this.isShowSubmenu = false;
    this.isShowSubmenuHover = false;
    if (this.authService.User.role == 'CLIENT') {
      this.links = this.getClientLinks();
      this.subLinks = this.getClientSubLinks();
    } else if (this.authService.User.role == 'EXPERT') {
      this.links = this.getExpertLinks();
      this.subLinks = this.getExpertSubLinks();
    } else {
      this.links = this.initLinks();
      this.subLinks = this.initSubLinks();
    }
  }

  initLinks() {
    return [
      {
        link: '/landing',
        name: 'Главная',
      },
      {
        link: 'experts',
        name: 'Эксперты',
      },
      {
        link: 'about',
        name: 'О ДЗЕНике',
      },
    ];
  }
  initSubLinks() {
    return [];
  }

  getClientLinks() {
    return [
      {
        link: 'c/orders',
        name: 'Мои консультации',
      },
      {
        link: 'c/profile',
        name: 'Профиль',
      },
      {
        link: 'c/experts',
        name: 'Эксперты',
      },
      {
        link: 'c/video',
        name: 'Видеокомната',
      },
    ];
  }
  getClientSubLinks() {
    return [
      {
        link: 'landing',
        name: 'Главная',
      },
      {
        link: 'c/notifications',
        name: 'Уведомления',
      },
      {
        link: '/c/profile/pay-history',
        name: 'Оплата',
      },
      {
        link: 'support',
        name: 'Служба поддержки',
      },
      {
        link: 'about',
        name: 'О ДЗЕНИКА',
      },
    ];
  }

  getExpertLinks() {
    return [
      {
        link: 'e/profile',
        name: 'Профиль',
      },
      // {
      //   link: '/auth',
      //   name: 'Auth'
      // },
      // {
      //   link: '/c',
      //   name: 'C'
      // },
      {
        link: 'e/clients',
        name: 'Мои клиенты',
      },
      {
        link: 'e/video',
        name: 'Видеокомната',
      },
      {
        link: 'e/schedule',
        name: 'Расписание',
      },
    ];
  }

  getExpertSubLinks() {
    return [
      {
        link: 'landing',
        name: 'Главная',
      },
      {
        link: 'e/notifications',
        name: 'Уведомления',
      },
      /*{
        link: 'finance',
        name: 'Финансы'
      },*/
      {
        link: '/e/profile/statistics',
        name: 'Статистика',
      },
      {
        link: 'support',
        name: 'Служба поддержки',
      },
      {
        link: 'support',
        name: 'О ДЗЕНИКА',
      },
    ];
  }

  clickLogout() {
    this.authService.Logout();
  }
}
