import { Component } from '@angular/core';
import {Modal} from "../../_modal/modal-options";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-expert-choose-modal',
  templateUrl: './expert-choose-modal.component.html',
  styleUrls: ['./expert-choose-modal.component.scss']
})
export class ExpertChooseModalComponent extends Modal {


  Id = 0;


  constructor(
      private authService: AuthService,
      private router: Router,
  ) {
    super();
  }


  onInjectInputs(inputs: any): void {
    this.Id = inputs['id'];
  }


  SaveExpert(){
    this.authService.SetChooseExpert(this.Id);
    this.router.navigate(['/auth/client/login']);
    this.close();

  }

}
