import { BaseModel } from "./base.model";

export class TokenModel extends BaseModel {
    override modelKey = 'token';
    token!: string;
    refreshToken!: string;

    constructor() {
        super();
        // this.deserialize(input)
    }
    
    saveToStorage() {
        const { modelKey,  ...saveObj} = this;
        this.clearStorage()
        localStorage.setItem(this.modelKey, JSON.stringify(saveObj))
    }

    isInStorage() {
        return localStorage.getItem(this.modelKey) ? true : false;
    }

    getFromStorage() {
       try { JSON.parse(localStorage.getItem(this.modelKey)!) } catch {localStorage.removeItem(this.modelKey)}
       return this.isInStorage() ? JSON.parse(localStorage.getItem(this.modelKey)!) : null
    }

    clearStorage() {
        localStorage.setItem(this.modelKey, '')
        localStorage.removeItem(this.modelKey)
    }
}