import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../environments/environment";

@Pipe({
    name: 'sessionStatusPipe',
})
export class SessionPipe implements PipeTransform {

    transform(
        value: string
    ): string{
        switch (value){
            case 'FINISHED':
                return 'Завершена'
            case 'WAITING':
                return 'Ожидается консультация'
            case 'CANCELED':
                return 'Отмена'
            default:
                return value;
        }
    }
}
