<div
  role="dialog"
  class="modal-container"
  (click)="close()"
  #elementscroll
>
  <div
    class="modal-content"
    [@inOutAnimation]="isOpen"
    *ngIf="isOpen"
    (click)="$event.stopPropagation()"
  >

    <div class="modal-header" *ngIf="header">
      <ng-template *ngTemplateOutlet="header"></ng-template>
      <button type="button" class="modal-btn" (click)="close()">
        <img class="modal-btn_svg" src="./assets/img/icons/Shape.svg">
      </button>
    </div>
    <div class="modal-body">
      <ng-content select="[modal-content]"></ng-content>
    </div>
  </div>
</div>
