<app-crumps-nav [breadcrumbs]="breadcrumbs"></app-crumps-nav>
<div class="container_account">
  <section class="main" id="main">
    <div class="main__container container">
      <div class="main__head">
        <h2 class="main__title title-second">
          Наши контакты
        </h2>
        <div class="main__caption title-second__caption">
          Если у вас появились вопросы, мы с радостью поможем вам во всем разобраться
        </div>
      </div>
      <div class="main__grid">
        <ul class="main__list">
          <li class="main__item">
            Телефон:
            <ul class="main-small__list">
              <li class="main-small__item">
                <a href="#" class="main-small__link">
                  <svg class="main-small__icon" width="18" height="19" viewBox="0 0 18 19" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 16.5V14.8541C18 14.0363 17.5021 13.3008 16.7428 12.9971L14.7086 12.1835C13.7429 11.7971 12.6422 12.2156 12.177 13.146L12 13.5C12 13.5 9.5 13 7.5 11C5.5 9 5 6.5 5 6.5L5.35402 6.32299C6.28438 5.85781 6.70285 4.75714 6.31654 3.79136L5.50289 1.75722C5.19916 0.997903 4.46374 0.5 3.64593 0.5H2C0.895431 0.5 0 1.39543 0 2.5C0 11.3366 7.16344 18.5 16 18.5C17.1046 18.5 18 17.6046 18 16.5Z"
                      fill="#FF6600"/>
                  </svg>
                  <b>8 800 555 35 35</b>
                </a>
              </li>
            </ul>
          </li>
          <li class="main__item">
            Почта:
            <ul class="main-small__list">
              <li class="main-small__item">
                <a href="mailto:info@dzenika.ru" class="main-small__link">
                  <svg class="main-small__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M23.6673 5C23.6673 7.57733 21.578 9.66667 19.0007 9.66667C16.4233 9.66667 14.334 7.57733 14.334 5C14.334 2.42267 16.4233 0.333336 19.0007 0.333336C21.578 0.333336 23.6673 2.42267 23.6673 5ZM19.0007 2.375C19.4839 2.375 19.8757 2.76675 19.8757 3.25V4.125H20.7507C21.2339 4.125 21.6257 4.51675 21.6257 5C21.6257 5.48325 21.2339 5.875 20.7507 5.875H19.0007C18.5174 5.875 18.1257 5.48325 18.1257 5V3.25C18.1257 2.76675 18.5174 2.375 19.0007 2.375ZM19.0007 11.4167V20.1667C19.0007 22.0997 17.4336 23.6667 15.5007 23.6667H3.83398C1.90099 23.6667 0.333984 22.0997 0.333984 20.1667V10.8333C0.333984 8.90034 1.90099 7.33334 3.83398 7.33334H13.0214C13.9548 9.72346 16.28 11.4167 19.0007 11.4167ZM4.30065 12.6417C4.5906 12.2551 5.13905 12.1768 5.52565 12.4667L8.09232 14.3917C9.02565 15.0917 10.309 15.0917 11.2423 14.3917L13.809 12.4667C14.1956 12.1768 14.744 12.2551 15.034 12.6417C15.3239 13.0283 15.2456 13.5768 14.859 13.8667L12.2923 15.7917C10.7368 16.9584 8.59787 16.9584 7.04232 15.7917L4.47565 13.8667C4.08905 13.5768 4.0107 13.0283 4.30065 12.6417Z"
                          fill="#FF6600"/>
                  </svg>
                  info@dzenika.ru
                </a>
              </li>
            </ul>
          </li>
          <li class="main__item">
            Мы в соц. сетях:
            <ul class="main-small__list">
              <li class="main-small__item">
                <a href="#" class="main-small__link">
                  <svg class="main-small__icon" width="21" height="18" viewBox="0 0 21 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17.4823 16.2901L17.483 16.2883L17.5004 16.2448L20.4992 1.12577V1.07666C20.4992 0.699962 20.36 0.371103 20.0571 0.173844C19.792 0.00118172 19.4873 -0.0111147 19.2736 0.00510583C19.0476 0.0222606 18.8344 0.0786761 18.6869 0.125636C18.611 0.149796 18.5475 0.173075 18.502 0.190681C18.4792 0.199518 18.4608 0.207016 18.4473 0.212603L18.4341 0.21816L1.71429 6.7771L1.70968 6.77875C1.70065 6.78203 1.68871 6.78649 1.67429 6.7921C1.64553 6.8033 1.60639 6.81931 1.56041 6.84015C1.47013 6.88107 1.3454 6.94421 1.21889 7.03084C1.00404 7.17797 0.594928 7.52629 0.66401 8.07852C0.721231 8.53593 1.03653 8.82643 1.2491 8.97683C1.36305 9.05746 1.47206 9.1156 1.55189 9.1536C1.59234 9.17286 1.6267 9.18761 1.65228 9.19802C1.66511 9.20325 1.67584 9.20743 1.68414 9.21057L1.69476 9.21454L1.70156 9.21706L4.6268 10.2019C4.61692 10.3854 4.63513 10.5725 4.6838 10.7572L6.14908 16.3156C6.32282 16.9746 6.91905 17.4337 7.60062 17.4332C8.21155 17.4328 8.75337 17.0632 8.98356 16.5118L11.2712 14.0657L15.2002 17.0779L15.2561 17.1023C15.6131 17.2581 15.9466 17.3074 16.2515 17.2658C16.556 17.2242 16.798 17.0965 16.9797 16.9511C17.1586 16.808 17.2812 16.6465 17.3582 16.5249C17.3974 16.4631 17.4266 16.4088 17.4469 16.3679C17.457 16.3474 17.465 16.3301 17.4711 16.3165L17.4786 16.2991L17.4813 16.2927L17.4823 16.2901ZM6.1342 10.3748C6.1009 10.2485 6.15318 10.1151 6.26346 10.0451L16.1855 3.74417C16.1855 3.74417 16.7691 3.38987 16.7482 3.74417C16.7482 3.74417 16.8524 3.80673 16.5396 4.09846C16.243 4.37554 9.46796 10.9166 8.78242 11.5785C8.74285 11.6167 8.71923 11.6605 8.70529 11.7137L7.59948 15.9332L6.1342 10.3748Z"
                          fill="#080729"/>
                  </svg>
                  name_telegram
                </a>
              </li>
              <li class="main-small__item">
                <a href="#" class="main-small__link">
                  <img class="main-small__icon" src="./assets/img/Whatsapp.png" alt="whatsapp">
                  name_whatsapp
                </a>
              </li>
              <li class="main-small__item">
                <a href="#" class="main-small__link">
                  <svg class="main-small__icon" width="22" height="13" viewBox="0 0 22 13" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.5035 12.881H18.222C17.3588 12.881 17.0992 12.182 15.5511 10.6339C14.2 9.33126 13.6292 9.16863 13.2868 9.16863C12.8145 9.16863 12.6847 9.29842 12.6847 9.9505V12.0021C12.6847 12.5573 12.5049 12.8825 11.0553 12.8825C9.64846 12.788 8.28422 12.3606 7.07493 11.6355C5.86564 10.9104 4.84592 9.9084 4.09975 8.71202C2.32828 6.50713 1.0957 3.91934 0.5 1.15442C0.5 0.811963 0.629791 0.502341 1.28187 0.502341H3.56182C4.14822 0.502341 4.35933 0.763487 4.5892 1.36553C5.69633 4.62438 7.58534 7.45789 8.35158 7.45789C8.64556 7.45789 8.77379 7.3281 8.77379 6.5947V3.2389C8.67684 1.70799 7.86369 1.5782 7.86369 1.02463C7.87413 0.878623 7.94104 0.742426 8.05022 0.644922C8.1594 0.547418 8.30227 0.496275 8.44853 0.502341H12.0326C12.5221 0.502341 12.6847 0.746286 12.6847 1.33269V5.8613C12.6847 6.35076 12.8958 6.51339 13.0428 6.51339C13.3368 6.51339 13.5635 6.35076 14.1015 5.81283C15.2569 4.40381 16.2009 2.83401 16.9037 1.15286C16.9756 0.950924 17.1115 0.778036 17.2908 0.660581C17.4701 0.543125 17.6829 0.487552 17.8967 0.502341H20.1782C20.8616 0.502341 21.007 0.844802 20.8616 1.33269C20.032 3.1911 19.0055 4.95518 17.7997 6.5947C17.5542 6.97 17.4557 7.16547 17.7997 7.60488C18.0265 7.94734 18.8256 8.61506 19.3635 9.24994C20.1452 10.0297 20.7944 10.9319 21.2853 11.9208C21.4808 12.5557 21.154 12.881 20.5035 12.881Z"
                      fill="#080729"/>
                  </svg>
                  name_vk
                </a>
              </li>
              <li class="main-small__item">
                <a href="#" class="main-small__link">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.9999 7.00005C15.9999 6.44776 16.4477 6.00005 16.9999 6.00005C17.5522 6.00005 17.9999 6.44776 17.9999 7.00005C17.9999 7.55233 17.5522 8.00005 16.9999 8.00005C16.4477 8.00005 15.9999 7.55233 15.9999 7.00005Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 7.25005C9.37659 7.25005 7.24994 9.37669 7.24994 12C7.24994 14.6234 9.37659 16.75 11.9999 16.75C14.6233 16.75 16.7499 14.6234 16.7499 12C16.7499 9.37669 14.6233 7.25005 11.9999 7.25005ZM8.74994 12C8.74994 10.2051 10.205 8.75005 11.9999 8.75005C13.7949 8.75005 15.2499 10.2051 15.2499 12C15.2499 13.795 13.7949 15.25 11.9999 15.25C10.205 15.25 8.74994 13.795 8.74994 12Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2582 2.83306C13.7916 2.44562 10.2083 2.44562 6.74171 2.83306C4.72965 3.05794 3.10532 4.64295 2.86876 6.66548C2.45423 10.2098 2.45423 13.7903 2.86876 17.3346C3.10532 19.3571 4.72965 20.9422 6.74171 21.167C10.2083 21.5545 13.7916 21.5545 17.2582 21.167C19.2702 20.9422 20.8946 19.3571 21.1311 17.3346C21.5457 13.7903 21.5457 10.2098 21.1311 6.66548C20.8946 4.64295 19.2703 3.05794 17.2582 2.83306ZM6.90832 4.32378C10.2642 3.94871 13.7357 3.94871 17.0916 4.32378C18.4217 4.47244 19.4872 5.52205 19.6413 6.83973C20.0423 10.2683 20.0423 13.7318 19.6413 17.1604C19.4872 18.478 18.4217 19.5277 17.0916 19.6763C13.7357 20.0514 10.2642 20.0514 6.90832 19.6763C5.57821 19.5277 4.51272 18.478 4.35861 17.1604C3.95761 13.7318 3.95761 10.2683 4.35861 6.83973C4.51272 5.52205 5.57821 4.47244 6.90832 4.32378Z" fill="black"/>
                  </svg>
                  inst_vk
                </a>
              </li>
            </ul>
          </li>
          <li class="main__item">
            Реквизиты:
            <ul class="main-small__list">
              <li class="main__item">ООО "АЛАР"</li>
              <li class="main__item">ИНН: 7733328719</li>
              <li class="main__item">ОГРН: 1187746588590</li>
              <li class="main__item">Адрес: 125430, Москва, ул.Митинская, 16, к.1917</li>
            </ul>
          </li>
        </ul>
        <img class="main__img img-cover" src="./assets/img/contacts.png">
      </div>
    </div>
  </section>
</div>
