import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-steps-line',
  templateUrl: './steps-line.component.html',
  styleUrls: ['./steps-line.component.scss']
})
export class StepsLineComponent {
  @Input() availableStep = 0;
  @Input() steps: any;
  @Input() currentStep: any;
  @Output() OnChangeStep = new EventEmitter<number>();

  constructor() { }

  ChangeStep(targetStep:number){
    if(this.availableStep>=targetStep){
      this.OnChangeStep.emit(targetStep);
    }

  }

}
