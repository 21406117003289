import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent {
  reviewsSlides = [
    {
      name: 'Дарья',
      text: '<p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p><p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p>'
    },
    {
      name: 'Дарья',
      text: '<p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p><p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p>'
    },
    {
      name: 'Дарья',
      text: '<p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p><p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p>'
    },
    {
      name: 'Дарья',
      text: '<p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p><p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p>'
    },
    {
      name: 'Дарья',
      text: '<p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p><p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p>'
    },
    {
      name: 'Дарья',
      text: '<p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p><p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p>'
    },
    {
      name: 'Дарья',
      text: '<p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p><p>Мы делаем упор не на количество, а на качество. Мы отбираем действительно хороших знающих профессионалов. Это основное и самое главное преимущество ДЗЕНики.</p>'
    }
  ];
}
