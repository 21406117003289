
<div [style.minWidth]="minWidthPx+'px'">
    <img *ngIf="imageLink"
        class="image-loaded"
        [ngClass]="{'show': isImageLoaded && !isImageError}" 
        [src]="imageLink" 
        [alt]="alt" 
        (load)="onLoaded()"
        (error)="onError()"
    >
    <img *ngIf="isImageLoaded" class="big-squiggle" src="assets/img/squiggle_auth.png">
    <img *ngIf="isImageLoaded" class="small-squiggle" src="assets/img/squiggle_auth.png">
    <div *ngIf="imageLink && !isImageLoaded && !isImageError">
        <app-preloader></app-preloader>
    </div>
    <div *ngIf="imageLink && !isImageLoaded && isImageError">
       <p>Ошибка загрузки изображения :(</p>
    </div>
    <div *ngIf="!imageLink">
        <p>Некорректное изображение</p>
    </div>
</div>
