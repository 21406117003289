import {Injectable} from "@angular/core";
import {MainService} from "./main.service";
import {LoadingService} from "./loading.service";
import {HttpClient} from "@angular/common/http";
import {map,filter} from "rxjs";


@Injectable({
    providedIn: "root",
})
export class QuestionsService {

    constructor(
        private mainService: MainService,
        private loadingService: LoadingService,
        private http: HttpClient
    ) {
    }


    GetLifeQuestionsList(){
        return this.mainService.SendGet(`/user/life-questions-list`);
    }



    GetDadata(query:string){
        return this.mainService.SendGet(`/user/dadata?query=${query}`)
            .pipe(
                map((res)=>{
                    return res['suggestions'];
                }),
                map((res:any[])=>{
                    return res
                        .map((item:any)=>{
                        return {
                            value:item['value'],
                            data:{
                                result:item['value'],
                                geo_lat:item['data']['geo_lat'],
                                geo_lon: item['data']['geo_lon'],
                            }

                        }
                    });
                }),
                map((res)=>{
                    return res.filter((item)=>item?.data?.geo_lon);
                })
            );
    }

    SendClientForm(data:any,loading = this.loadingService.StatesNames.SEND_FORM){
        return this.mainService.SendPost('/client/form',data,loading);
    }

    SendExpertFile(form:any){
        return this.mainService.SendPost('/expert/form-file',form,this.loadingService.StatesNames.ADD_EDUCATION_IMAGE);

    }

    RemoveExpertFile(id:number){
        return this.mainService.SendDelete(`/expert/form-file/${id}`,{});

    }

    SendExpertForm(form:any){
        return this.mainService.SendPost('/expert/form',form);

    }



}
