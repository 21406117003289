import { Component } from '@angular/core';
import { Modal } from '../../_modal/modal-options';

import { ReviewService } from 'src/app/services/review.service';
import { EventsService } from '../../services/events.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-leave-review-modal',
  templateUrl: './leave-review-modal.component.html',
  styleUrls: ['./leave-review-modal.component.scss'],
})
export class LeaveReviewModalComponent extends Modal {
  private sessionId!: number;

  constructor(
    private eventsService: EventsService,
    public loadingService: LoadingService,
    protected readonly reviewService: ReviewService
  ) {
    super();
  }
  onInjectInputs({
    clientComment,
    clientRating,
    sessionId,
  }: {
    clientComment?: string;
    clientRating?: number;
    sessionId: number;
  }): void {
    this.sessionId = sessionId;
    this.clientComment = clientComment ?? '';
    this.rating = clientRating ?? 0;
  }

  rating: number = 0;
  stars: number[] = [1, 2, 3, 4, 5];
  clientComment = '';

  onStarClick(star: number) {
    this.rating = star;
  }

  Send() {
    this.eventsService.resetAllEvents();
    if (this.rating && this.clientComment) {
      const payload = {
        comment: this.clientComment,
        rating: this.rating,
      };
      this.reviewService.save(this.sessionId, payload).subscribe({
        next: () => {
          this.eventsService.success('Отзыв сохранён!');
          this.close(payload);
        },
        error: (e) => {
          this.eventsService.throwError(
            'Что-то пошло не так, попробуйте снова!'
          );
          this.dismiss(e);
        },
      });
    } else {
      this.eventsService.throwError('Поставьте оценку и напишите комментарий!');
    }
  }
}
