import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  breadcrumbs: Array<{ text: string, link: string }> = [
    {
      text: 'Главная',
      link: '/landing'
    },
    {
      text: 'О ДЗЕНике',
      link: '/about'
    },
  ];
  TeamSlides: any[] = [
    {
      name: 'Валентина Козырева',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team1.png"
    },
    {
      name: 'Анастасия Сапрыкина',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team2.png"
    },
    {
      name: 'Регина Соколова',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team3.png"
    },
    {
      name: 'Иван Стрельцов',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team4.png"
    },
    {
      name: 'Валентина Козырева',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team1.png"
    },
    {
      name: 'Анастасия Сапрыкина',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team2.png"
    },
    {
      name: 'Регина Соколова',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team3.png"
    },
    {
      name: 'Иван Стрельцов',
      position: 'Директор компании',
      img: "../../../../assets/img/about/team4.png"
    },
  ]

  constructor() {
  }

  ngOnInit(): void {
  }
}
