<section class="reviews" id="reviews">
  <div class="reviews__container container">
    <h2 class="reviews__title title-second">
      Отзывы о ДЗЕНике
    </h2>
    <app-slider
      [SliderPerView]="3"
      [SpaceBetween]="20"
    >
      <swiper-slide *ngFor=" let slide of reviewsSlides">
        <div class="reviews-slider__card">
          <h5 class="reviews-slider__title">
            {{ slide.name }}
          </h5>
          <div class="reviews-slider__text" [innerHTML]="slide.text"></div>
        </div>
      </swiper-slide>
    </app-slider>
  </div>
</section>
