<ng-template #headerRef>
    <h5 class="expert-page-consultation-modal__title modal-title">Консультация</h5>
</ng-template>
<app-modal [header]="headerRef" (destroy)="dismiss()">
    <div modal-content class="expert-page-consultation-modal__content">
        <p>Для продолжения необходимо войти или зарегистрироваться</p>
        <div class="buttons">
            <button class="modal-submit btn-custom btn-approve btn-orange" (click)="SaveExpert()">Продолжить</button>
            <button class="btn-custom btn-none" (click)="dismiss()">Отмена</button>
        </div>
    </div>
</app-modal>
