<app-crumps-nav [breadcrumbs]="breadcrumbs"></app-crumps-nav>
<div class="container_account">
  <section class="main" id="main">
    <div class="main__container container">
      <div class="title-second__caption caption_grey main__caption">
        Здесь вы найдете то, что необходимо именно вам
      </div>
      <h2 class="main__title title-second">
        Я, ДЗЕНика, приветствую вас <br>на своем сайте!
      </h2>
      <div class="main__grid">
        <div class="main__col">
          <div class="main__elem main__elem_text">
            Над моим созданием работало множество специалистов. Я создана для того, чтобы избавить вас от разного
            мусора, которого так много в интернете. Именно поэтому вы не найдете здесь никаких курсов «успешного успеха»
            от инфоцыган.
            Здесь представлены только те практики, техники и системы, которые действительно дают результаты и одобрены
            профессионалами. Более того, сами специалисты, которые будут работать с вами, отобраны и тщательно проверены
            мной. Вам не нужно тратить время, нервы и деньги на подбор подходящего специалиста!
          </div>
          <div class="main__elem main__elem_img">
            <img class="img-cover" src="./assets/img/about/main2.png">
          </div>
        </div>
        <div class="main__col">
          <div class="main__elem main__elem_img main__elem_img_long">
            <img class="img-cover" src="./assets/img/about/main1.png">
          </div>
          <div class="main__elem main__elem_text">
            Пока здесь представлен только начальный функционал – ведическая астрология, основанная на священных
            индийских ведических писаниях, проверенная тысячелетиями и признанная в Индии. В дальнейшем я буду
            расширяться и развиваться вместе с вами. Я рада знакомству и рада, что смогу помочь вам на вашем пути. Ваша
            ДЗЕНика.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="mission" id="mission">
    <div class="container">
      <h2 class="mission__title title-second">
        Наша миссия – быть пространством, где каждый может найти свой путь к успеху и самореализации
      </h2>
      <img class="mission__img img-cover" src="./assets/img/about/mission.png">
    </div>
  </section>
  <section class="symbiosis" id="symbiosis">
    <div class="container">
      <div class="symbiosis__row">
        <div class="symbiosis__col">
          <h2 class="symbiosis__title title-second">
            Симбиоз древних знаний и современных техник
          </h2>
          <div class="symbiosis__caption title-second__caption caption_grey">
            Мы здесь, чтобы помочь каждому найти свой путь к счастью и успеху
          </div>
        </div>
        <div class="symbiosis__col symbiosis__col_text">
          <div class="symbiosis__text title-second__caption">
            <p>
              Консультации сертифицированных астрологов, нумерологов и других специалистов, получивших соответствующее
              образование и прошедших строгий отбор в команду ДЗЕНики
            </p>
            <p>
              <b>> ХХ специалистов</b>
            </p>
            <p>
              готовы помочь вам обрести гармонию
            </p>
          </div>
        </div>
      </div>
      <img class="symbiosis__img img-cover" src="./assets/img/about/symbiosis.png">
    </div>
  </section>
  <section class="efficiency" id="efficiency">
    <div class="container">
      <div class="efficiency__head">
        <h2 class="efficiency__title title-second">
          Все эффективные методы самопознания в одном месте
        </h2>
        <div class="efficiency__caption title-second__caption">
          Системы, отобранные ДЗЕНикой, признаны в профессиональных сообществах и проверены временем. ДЗЕНика тщательно
          подходит к выбору и предлагает только те методы, которые действительно приносят результат
        </div>
      </div>
      <div class="efficiency__grid">
        <div class="efficiency__item">
          <div class="efficiency__number">1</div>
          <div class="efficiency__text">
            <b>Дают</b>
            <p>знания о характере и душе человека</p>
          </div>
        </div>
        <div class="efficiency__item">
          <div class="efficiency__number">2</div>
          <div class="efficiency__text">
            <b>Учат</b>
            <p>управлять своей жизнью, понимать свою судьбу и выбирать дальнейший путь</p>
          </div>
        </div>
        <div class="efficiency__item">
          <div class="efficiency__number">3</div>
          <div class="efficiency__text">
            <b>Помогают</b>
            <p>познавать себя и разбираться в различных жизненных ситуациях</p>
          </div>
        </div>
      </div>
      <div class="efficiency__block efficiency__caption">
        Это не история «успешного успеха», здесь нет гаданий, таро и магии. <br>ДЗЕНика – это признанные мировой
        практикой
        инструменты для понимания себя и окружающего мира.
      </div>
    </div>
  </section>
  <section class="various" id="various">
    <div class="container">
      <div class="various__row">
        <div class="various__col">
          <h2 class="various__title title-second">
            Исследуйте себя и свою жизнь с различных точек зрения
          </h2>
        </div>
        <div class="various__col various__col_text">
          <div class="title-second__caption">
            ДЗЕНика предлагает уникальный подход к самопознанию, позволяет разгадывать тайны нашего характера и судьбы
          </div>
        </div>
      </div>
      <div class="various__grid">
        <div class="various__item">Соционика</div>
        <div class="various__item">Дизайн человека</div>
        <div class="various__item">МАК</div>
        <div class="various__item">Астрология</div>
        <div class="various__item">Фен-шуй</div>
        <div class="various__item">Подход</div>
      </div>
      <div class="various__block title-second__caption">
        Предложенные ДЗЕНикой системы знаний гармонично дополняют друг друга. <br>Рекомендуем обращаться к разным
        подходам,
        чтобы увидеть полную картину и получить исчерпывающие ответы о вселенной и подсознании человека.
      </div>
    </div>
  </section>
  <section class="problems" id="problems">
    <div class="container">
      <h2 class="problems__title title-second">
        Какие проблемы решает ДЗЕНика
      </h2>
      <ul class="problems__list">
        <li class="problems__item">
          <div class="problems__imgs">
            <img class="problems__svg" src="./assets/img/about/circle.svg">
            <img class="problems__img img-cover" src="./assets/img/about/problems1.png">
          </div>
          <div class="problems__texts">
            <div class="problems__number problems__subtitle">01.</div>
            <div class="problems__text">
              <h5 class="problems__subtitle">
                Непонимание себя и своей жизни, повторяющиеся «грабли»
              </h5>
              <p>
                Это значит, что в жизни есть какой-то сценарий, который нужно понять и успешно разгадать. Чтобы выбрать
                правильный путь, важно понимать самого себя и свои истинные желания, слышать свою душу и иметь смелость
                слушаться ее.
              </p>
              <p>
                ДЗЕНика учитывает индивидуальные характеристики и потребности конкретного человека,
                помогает разобраться в себе и своих целях, определить свои сильные и слабые стороны, способствует
                личностному росту и саморазвитию.
              </p>
            </div>
          </div>
        </li>
        <li class="problems__item">
          <div class="problems__texts">
            <div class="problems__number problems__subtitle">02.</div>
            <div class="problems__text">
              <h5 class="problems__subtitle">
                Чувство одиночества
              </h5>
              <p>
                Кажется, что вы один на один со своей проблемой? Хочется закрыться от всего мира? Так бывает, даже если
                у вас есть семья и близкие друзья.
              </p>
              <p>
                ДЗЕНика – это пространство знаний, дружелюбия, легкости и хорошего настроения. Мы объединяем людей,
                которые ищут ответы на вопросы о судьбе, мироздании и о месте человека в нем, стремятся к саморазвитию,
                гармонии и счастью. Здесь вы обретете новых друзей и единомышленников, получите новые знания и навыки,
                разовьете свои таланты, станете частью яркого и дружного комьюнити!
              </p>
            </div>
          </div>
          <div class="problems__imgs problems__imgs_right">
            <img class="problems__svg problems__svg_right" src="./assets/img/about/circle.svg">
            <img class="problems__img problems__img_right img-cover" src="./assets/img/about/problems2.png">
          </div>
        </li>
        <li class="problems__item">
          <div class="problems__imgs">
            <img class="problems__svg" src="./assets/img/about/circle.svg">
            <img class="problems__img img-cover" src="./assets/img/about/problems3.png">
          </div>
          <div class="problems__texts">
            <div class="problems__number problems__subtitle">03.</div>
            <div class="problems__text">
              <h5 class="problems__subtitle">
                Подавленное состояние
              </h5>
              <p>
                Депрессия, уныние, апатия, нехватка энергии, обида – в такие моменты сложно понять, как правильно
                поступить и какой путь выбрать.
              </p>
              <p>
                ДЗЕНика считает, что самое ценное для человека – это его здоровье и
                человек здоров тогда, когда он находится в гармонии с окружающим миром. Здесь вы получите инструменты
                для создания гармонии внутри себя и строительства счастливого и осознанного будущего, станете открыты к
                новым возможностям и используете их для личностного роста и самопознания.
              </p>
            </div>
          </div>
        </li>
        <li class="problems__item">
          <div class="problems__texts problems__texts_pt-20">
            <div class="problems__number problems__subtitle">04.</div>
            <div class="problems__text">
              <h5 class="problems__subtitle">
                Сложно выбрать кому довериться
              </h5>
              <p>
                Сейчас много псевдоспециалистов, которые, закончив недельные курсы, не имея опыта и знаний, начинают
                учить жизни других и могут нанести огромный ущерб психике человека.
              </p>
              <p>
                ДЗЕНика проводит строгий отбор экспертов, проверяет их навыки и знания. Все эксперты проходят
                специальные экзамены, имеют соответствующие сертификаты, реальные кейсы и рекомендации.
              </p>
            </div>
          </div>
          <div class="problems__imgs problems__imgs_right">
            <img class="problems__svg problems__svg_right" src="./assets/img/about/circle.svg">
            <img class="problems__img problems__img_right img-cover" src="./assets/img/about/problems4.png">
          </div>
        </li>
      </ul>
    </div>
  </section>
  <section class="team" id="team">
    <div class="container">
      <div class="team__head">
        <h2 class="team__title title-second">
          Команда, которая каждый день работает над проектом
        </h2>
        <div class="team__caption title-second__caption">
          Мы искренне ценим вас и всегда ставим в приоритет качество предлагаемых услуг
        </div>
      </div>
      <app-slider
        [SliderPerView]="4"
        [SpaceBetween]="20"
      >
        <swiper-slide *ngFor=" let slide of TeamSlides">
          <div class="team-slider__card" [style.background-image]="'url(' + slide.img + ')'">
            <div class="team-slider__block">
              <h5 class="team-slider__title">
                {{ slide.name }}
              </h5>
              <div class="team-slider__caption caption_grey">
                {{ slide.position }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </app-slider>
    </div>
  </section>
  <section class="start" id="start">
    <div class="container">
      <div class="start__wrap">
        <img class="start__svg start__svg_top" src="./assets/img/about/circle.svg">
        <img class="start__svg start__svg_bottom" src="./assets/img/about/circle.svg">
        <div class="start__content">
          <div class="start__block">
            Вы готовы взять жизнь в свои руки?
          </div>
          <h2 class="start__title title-second">
            Начните сами управлять своей жизнью
          </h2>
          <div class="start__text">
            <p class="start__paragraph">
              Жизнь – это квест, давайте разбираться в нем вместе
            </p>
            <p class="start__paragraph">
              ДЗЕНика – это образ жизни, определенная культура и этика, яркие события, движение вперед и добрые эмоции.
              В
              нашем Пространстве много позитивной энергии и уверенности в лучшем, потому что все задачи на нашем пути
              нужны только для того, чтобы мы развивались и шли к своему счастью.
            </p>
            <p class="start__paragraph">
              Нужна помощь с формированием запроса или выбором методики? <br>Оставьте заявку, мы свяжемся с вами в ближайшее
              время и обсудим ваши тревоги.
            </p>
          </div>
          <button routerLink="/experts" class="start__link btn-custom btn-approve btn-orange">
            Консультация
          </button>
        </div>
        <img class="start__img img-cover" src="./assets/img/about/start.png">
      </div>
    </div>
  </section>
</div>
