import { BaseModel } from './base.model';

export class UserModel extends BaseModel {
  id!: number;
  phone!: string;
  role!: UserRole;
  mainForm!: IMainForm;
  createdAt!: Date;
  tariff?: string | null;
  avatarUrl!: string;
  unreadNotification!: number;

  constructor() {
    super();
    // this.deserialize(input)
  }
}

export enum UserRole {
  'CLIENT' = 'CLIENT',
  'EXPERT' = 'EXPERT',
}

interface IMainForm {
  name: string;
  email: string;
  place: IPlace;
  birthday: Date;
  timezone: number;
  birthdayTime?: string;
  contactPhone?: string;
  country?: string;
  paymentInfo?: string;
  additionalInfo?: string;
  offers?: string;
  specialization?: string;
}

interface IPlace {
  geo_lat: string;
  geo_lon: string;
  result: string;
}
