import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {



  @Input() type = '';
  @Input() role = '';
  @Input() additionalInfo:any;
  //SESSION_MOVED - перенос (Общее)
  //SESSION_REMIND - напоминание
  //SESSION_CANCELLED - отмена
  //PAYMENT_COMPLETE - оплата прошла
  //PAYMENT_CANCELLED - оплата отменена
  //NEW_SESSION - новая запись
  //LEVEL_CHANGED - уровень изменён
  //TARIFF_CHANGED - тип тарифа изменён


}
