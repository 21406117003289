import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0.3 }),
        animate('0.2s ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ModalComponent {
  @Input() isOpen = true;

  @Input() header?: TemplateRef<void>;
  @Output() destroy = new EventEmitter();

  close() {
    this.isOpen = false;
    this.destroy.emit();
  }

  @HostListener('window:keydown.escape', []) onKeydownHandler() {
    this.close();
  }
}
