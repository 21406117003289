<section class="questions" id="questions">
    <div class="questions__container container">
      <h2 class="advantages__title title-second title-second_center">Часто задаваемые вопросы</h2>
      <div class="questions-accordion">
        <ul class="questions-accordion__list">
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №1
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №3
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №5
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №7
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
        </ul>
        <ul class="questions-accordion__list">
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №2
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №4
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №6
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
          <li class="questions-accordion__item">
            <div class="questions-accordion__content">
              <input class="questions-accordion__input" type="checkbox" checked>
              <div class="questions-accordion__head">
                <h3 class="questions-accordion__title">
                  Текст вопроса №8
                </h3>
                <img class="questions-accordion__arrow" src='../../../../assets/img/icons/direaction%20right.svg'
                     alt="icon">
              </div>
              <div class="questions-accordion__body">
                <p>
                  Противоположная точка зрения подразумевает, что представители современных социальных резервов освещают
                  чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, превращены в
                  посмешище, хотя само их существование приносит несомненную пользу обществу.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>