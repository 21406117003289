interface Deserializable {
    deserialize(input: any): this;
}

export class BaseModel implements Deserializable {
    modelKey!: string;

    deserialize(input: Object, fromCase: TyperCase = 'snake_case'): this {
        Object.assign(this, this.convertFromAnotherCase(input, fromCase));
        return this;
    }

    convertFromAnotherCase(input: any, fromCase: TyperCase) {
        if (fromCase == 'camelCase') return input;

        let cases: string[] = [...Object.keys(input)]
        let newCases = cases.map(k => {
            if (fromCase == 'snake_case') {
              let kName = k.split('_')
              return kName[0] + (kName.splice(1).map(x=>x[0].toUpperCase() + x.slice(1).toLocaleLowerCase()).join(''))
            }
            if (fromCase == 'kebab-case') {
                let kName = k.split('-')
                return kName[0] + (kName.splice(1).map(x=>x[0].toUpperCase() + x.slice(1).toLocaleLowerCase()).join(''))
            }
            return k;
        })
        
        let newInput: any = {}
        newCases.forEach((e,i) => {
            newInput[e] = input[cases[i]]
        })
    
        return newInput;
    }
}

type TyperCase = 'snake_case'|'kebab-case'|'PascalCase'|'camelCase'|'UPPER_SNAKE_CASE';