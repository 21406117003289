import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-expert-card',
  templateUrl: './expert-card.component.html',
  styleUrls: ['./expert-card.component.scss']
})
export class ExpertCardComponent {
  @Input() Id = 0;
  @Input() imageUrl: string|null = '';
  @Input() name: string = '';
  @Input() level: number = 0;
  @Input() profession: string = '';
  @Input() rating: number = 0;
  @Input() description: string = '';
  @Input() visibleProf: boolean = true;
  @Input() isFromBack = true;
  @Input() avgRating: number = 0;
  @Input() hideRating:boolean = false;
  protected readonly Array = Array;

  @Output() OnChoose = new EventEmitter();


  ChooseExpert(){
    this.OnChoose.emit(this.Id)
  }

    protected readonly Math = Math;
}
