import { Component } from '@angular/core';
import {Modal} from "../../_modal/modal-options";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MismatchValidator} from "../validators/mismatch.validator";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-changing-the-password-modal',
  templateUrl: './changing-the-password-modal.component.html',
  styleUrls: ['./changing-the-password-modal.component.scss']
})
export class ChangingThePasswordModalComponent extends Modal {

  form:any = new FormGroup({
    currentPassword: new FormControl(null,[Validators.required,Validators.minLength(8),Validators.maxLength(32)]),
    password: new FormControl('',[Validators.required,Validators.minLength(8),Validators.maxLength(32)]),
    passwordConfirmation: new FormControl('', [Validators.required, Validators.minLength(8),Validators.maxLength(32)])
  })

  constructor(public loadingService: LoadingService) {
    super();
  }
  onInjectInputs(inputs: any): void {

    this.form.get('passwordConfirmation').addValidators([MismatchValidator.mismatch(this.form.get('password'))]);
    this.ValidatePassword();
    if(inputs['passwordError']){
      this.form.get('currentPassword').setErrors({incorrect:true})
    }
    if(inputs['OldNewPassword']){
      this.form.get('password').setErrors({incorrect:true});
    }

  }

  ValidatePassword(){
    this.form.get('password').valueChanges.subscribe(()=>{
      this.form.get('passwordConfirmation').updateValueAndValidity();
    })
  }

  Send() {
    for (const i in this.form.controls) {
      this.form.get(i)?.updateValueAndValidity();
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if(this.form.valid){
      this.send(this.form.getRawValue());
    }


  }

}
