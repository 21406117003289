import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../environments/environment";

@Pipe({
    name: 'payStatusPipe',
})
export class PayStatusPipe implements PipeTransform {

    transform(
        value: string
    ): string{
        switch (value){
            case 'FINISHED':
                return 'Оплачено'
            case 'PENDING':
                return 'Ожидает оплаты'
            case 'CANCELLED':
                return 'Отменен'
            default:
                return value;
        }
    }
}
