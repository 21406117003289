import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-how-works-slider',
  templateUrl: './how-works-slider.component.html',
  styleUrls: ['./how-works-slider.component.scss']
})
export class HowWorksSliderComponent {
  @Input() slideVisible:boolean = true;
}
