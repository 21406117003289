import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ExpertsService} from "../../../services/experts.service";
import {EventsService} from "../../../services/events.service";

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.scss']
})
export class VacancyComponent implements OnInit {
  breadcrumbs: Array<{ text: string, link: string }> = [
    {
      text: 'Главная',
      link: '/landing'
    },
    {
      text: 'Вакансии',
      link: '/vacancy'
    },
  ];

  constructor(
    private expertsService: ExpertsService,
    private eventsService: EventsService,
  ) {
  }

  ngOnInit(): void {
  }

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.minLength(18)]),
    email: new FormControl('', [Validators.required, Validators.email]),
  })

  Send() {
    for (const i in this.form.controls) {
      this.form.get(i)?.updateValueAndValidity();
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const data: any = {
        to: "info@dzenika.ru",
        text: `Имя: ${this.form.controls.name.value}. <br>Номер телефона: ${this.form.controls.phone.value} <br>Почта: ${this.form.controls.email.value}`,
      };
      console.log(data)

      this.expertsService.PostSendEmail(data).subscribe({
        next: (res: any) => {
          this.eventsService.success('Сообщение успешно отправлено!');
          this.form.reset({
            name: '',
            phone: '',
            email: ''
          }, {emitEvent: false});
        },
        error: (error: any) => {
          this.eventsService.throwError('Что-то пошло не так');
        }
      });
    }
  }
}
