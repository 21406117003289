import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  config = {
    id: 'custom',
    itemsPerPage: 6,
    currentPage: 1,
    totalItems: 2,
  };

  @Input() role = '';

  notificationList = [];
  breadcrumbs: Array<{ text: string; link: string }> = [
    {
      text: 'Главная',
      link: '/landing',
    },
    {
      text: 'Уведомления',
      link: '/notifications/list',
    },
  ];

  constructor(private notificationsService: NotificationService) {}
  ngOnInit(): void {
    // resets notification counter when nav to the page
    this.notificationsService.resetCounter();
    this.GetList().subscribe();
  }

  GetList() {
    return this.notificationsService
      .GetNotificationList(
        this.config.currentPage - 1,
        this.config.itemsPerPage
      )
      .pipe(
        tap((res) => {
          this.notificationList = res['content'];
          this.config.totalItems = res['totalElements'];
        })
      );
  }

  ChangePage(event: any) {
    if (this.config.currentPage !== event) {
      this.config.currentPage = event;
      this.GetList().subscribe();
    }
  }
}
