import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'monthPipe',
})
export class MonthPipe implements PipeTransform {

    transform(
        value: any
    ): string{
        console.log("transform",value)
        let month = value.split('.')[0];
        const year = value.split('.')[1];
        console.log("month",month);

        switch (month){
            case 'янв':
                month = 'Январь';
                break;
            case 'февр':
                month = 'Февраль';
                break;
            case 'мар':
                month = 'Март';
                break;
            case 'апр':
                month = 'Апрель';
                break;
            case 'мая':
                month = 'май';
                break;
            case 'июн':
                month = 'Июнь';
                break;
            case 'июл':
                month = 'Июль';
                break;
            case 'авг':
                month = 'Август';
                break;
            case 'сент':
                month = 'Сентябрь';
                break;
            case 'окт':
                month = 'Октябрь';
                break;
            case 'нояб':
                month = 'Ноябрь';
                break;
            case 'дек':
                month = 'Декабрь';
                break;
            default:
                month = '';
                break;
        }
        return month + ' ' +year;
    }
}
