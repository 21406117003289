<div class="stepper">
    <div class="step" *ngFor="let step of steps; let i = index">
        <div class="line" *ngIf="i !== 0" [class.active]="i < currentStep || i < availableStep"></div>
        <a (click)="ChangeStep(i+1)">
            <div class="dot" [class.active]="i < currentStep || i < availableStep">
                <div *ngIf="i < currentStep || i < availableStep" width="24" height="24">
                    <img src="../../../assets/img/dot_img.svg" alt="">
                </div>
                <div *ngIf="i >= currentStep && i >= availableStep" width="24" height="24">
                    <img src="../../../assets/img/dot_img_empty.svg" alt="">
                </div>
                <span class="tooltip">{{ step.name }}</span>
            </div>
        </a>

    </div>
</div>
