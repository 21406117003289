<!--<div class="config">
<p>API: {{apiUrl}}</p>
<p>Stage: {{stage}}</p>
<button (click)="authService.testChangeTokenToInvalid()">Сломать токен</button>
<button (click)="authService.testGetMe()"> GetMe</button>
</div>-->

<!-- <div *ngIf="isLoadingUser">
    <p>LOADING USER...</p>
</div> -->
<div id="top-section">
    <router-outlet></router-outlet>
</div>
