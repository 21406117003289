<ng-template #headerRef>
  <h5 class="leave-review-modal__title modal-title">Оставить отзыв</h5>
</ng-template>
<app-modal [header]="headerRef" (destroy)="dismiss()">
  <div modal-content class="leave-review-modal__content">
    <div class="leave-review-modal__row">
      <svg
        *ngFor="let star of stars"
        [class.leave-review-modal__svg_active]="star <= rating"
        (click)="onStarClick(star)"
        class="leave-review-modal__svg"
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
      >
        <path
          d="M17.5574 5.72497C18.9656 2.75835 23.0344 2.75834 24.4426 5.72497L26.8763 10.8521C27.4355 12.0301 28.5164 12.8466 29.7668 13.0355L35.2088 13.8577C38.3577 14.3334 39.615 18.3567 37.3365 20.6659L33.3986 24.6568C32.4938 25.5738 32.0809 26.8949 32.2945 28.1897L33.2241 33.825C33.762 37.0856 30.4703 39.5721 27.6539 38.0326L22.7864 35.3721C21.6681 34.7607 20.3319 34.7607 19.2136 35.3721L14.3461 38.0326C11.5297 39.5721 8.23799 37.0856 8.77588 33.825L9.70548 28.1897C9.91907 26.8949 9.5062 25.5738 8.60139 24.6568L4.66353 20.6659C2.38502 18.3567 3.64232 14.3334 6.79116 13.8577L12.2332 13.0355C13.4836 12.8466 14.5645 12.0301 15.1237 10.8521L17.5574 5.72497Z"
          fill="#DFDFDF"
          stroke="#D1D1D1"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <textarea
      [(ngModel)]="clientComment"
      class="leave-review-modal__textarea input-textarea input-custom"
      type="text"
      maxlength="150"
      placeholder="Введите текст"
    ></textarea>
    <app-preloader
      *ngIf="
        loadingService.States[
          loadingService.getIdFromArrayStateByType(
            loadingService.StatesNames.CHANGE_SESSION
          )
        ].value;
        else btn
      "
    >
    </app-preloader>
    <ng-template #btn>
      <button
        (click)="Send()"
        class="modal-submit btn-custom btn-approve btn-orange"
      >
        Отправить
      </button>
    </ng-template>
  </div>
</app-modal>
