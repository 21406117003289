<footer class="footer">
  <div class="footer__container container">
    <div class="footer-content">
      <div class="contact-block">
        <img src="../../../assets/img/logo.png" alt="logo" class="logo">
        <div>
          <img src="../../../assets/img/call.svg" alt="">
          <p>8 800 555 35 35</p>
        </div>
        <div>
          <img src="../../../assets/img/mail-delay.svg" alt="">
          <p>info@dzenika.ru</p>
        </div>
        <div>
          <a href="/"><img src="../../../assets/img/Telegram.svg" alt="telegram"></a>
          <a href="/"><img src="../../../assets/img/Whatsapp.png" alt="whatsapp"></a>
          <a href="/"><img src="../../../assets/img/Vk.svg" alt="vk"></a>
        
            <a href="/" >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9999 7.00005C15.9999 6.44776 16.4477 6.00005 16.9999 6.00005C17.5522 6.00005 17.9999 6.44776 17.9999 7.00005C17.9999 7.55233 17.5522 8.00005 16.9999 8.00005C16.4477 8.00005 15.9999 7.55233 15.9999 7.00005Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 7.25005C9.37659 7.25005 7.24994 9.37669 7.24994 12C7.24994 14.6234 9.37659 16.75 11.9999 16.75C14.6233 16.75 16.7499 14.6234 16.7499 12C16.7499 9.37669 14.6233 7.25005 11.9999 7.25005ZM8.74994 12C8.74994 10.2051 10.205 8.75005 11.9999 8.75005C13.7949 8.75005 15.2499 10.2051 15.2499 12C15.2499 13.795 13.7949 15.25 11.9999 15.25C10.205 15.25 8.74994 13.795 8.74994 12Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2582 2.83306C13.7916 2.44562 10.2083 2.44562 6.74171 2.83306C4.72965 3.05794 3.10532 4.64295 2.86876 6.66548C2.45423 10.2098 2.45423 13.7903 2.86876 17.3346C3.10532 19.3571 4.72965 20.9422 6.74171 21.167C10.2083 21.5545 13.7916 21.5545 17.2582 21.167C19.2702 20.9422 20.8946 19.3571 21.1311 17.3346C21.5457 13.7903 21.5457 10.2098 21.1311 6.66548C20.8946 4.64295 19.2703 3.05794 17.2582 2.83306ZM6.90832 4.32378C10.2642 3.94871 13.7357 3.94871 17.0916 4.32378C18.4217 4.47244 19.4872 5.52205 19.6413 6.83973C20.0423 10.2683 20.0423 13.7318 19.6413 17.1604C19.4872 18.478 18.4217 19.5277 17.0916 19.6763C13.7357 20.0514 10.2642 20.0514 6.90832 19.6763C5.57821 19.5277 4.51272 18.478 4.35861 17.1604C3.95761 13.7318 3.95761 10.2683 4.35861 6.83973C4.51272 5.52205 5.57821 4.47244 6.90832 4.32378Z" fill="black"/>
              </svg>
            </a>
         
          
        </div>
      </div>
      <!--        <div class="columns">-->
      <div class="column">
        <h4>Клиентам</h4>
        <ul>
          <li><a [routerLink]="'experts'">Эксперты</a></li>
          <!--<li><a href="#">Сферы жизни</a></li>-->
          <li><a [routerLink]="'support'">Вопросы-ответы</a></li>
          <!-- <li><a href="#">Гарантии качества</a></li> -->
        </ul>
      </div>
      <div class="column">
        <h4>Экспертам</h4>
        <ul>
          <li><a routerLink="/vacancy">Стать экспертом</a></li>
        </ul>
      </div>
      <div class="column">
        <h4>Дополнительно</h4>
        <ul>
          <li><a [routerLink]="'about'">О нас</a></li>
          <!-- <li><a href="#">Города</a></li> -->
          <li><a routerLink="/vacancy">Вакансии</a></li>
          <!-- <li><a href="#">Статьи</a></li> -->
          <li><a routerLink="/contacts">Контакты</a></li>
        </ul>
      </div>
      <div class="column">
        <h4>Условия использования</h4>
        <ul>
          <li><a href="#">Политика конфиденциальности</a></li>
          <li><a href="#">Договор оферты</a></li>
          <li><a href="#">Правила пользования сервиса</a></li>
        </ul>
      </div>
      <!--        </div>-->
    </div>
  </div>
</footer>
