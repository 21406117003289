import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {ExpertsService} from "../../../services/experts.service";
import {tap} from "rxjs/operators";
import {ExpertModel} from "../../../models/experts.model";
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {LoadingService} from "../../../services/loading.service";

// import {ExpertsService} from "../../../services/experts.service";

@Component({
  selector: 'app-experts',
  templateUrl: './experts.component.html',
  styleUrls: ['./experts.component.scss']
})
export class ExpertsComponent implements OnInit{
  breadcrumbs: Array<{ text: string, link: string }> = [
    {
      text: 'Главная',
      link: '/landing'
    },
    {
      text: 'Эксперты',
      link: '/experts'
    },
  ];


  constructor(
      private expertService: ExpertsService,
      private router: Router,
      private authService: AuthService,
      public loadingService: LoadingService
  ) {
  }

  protected readonly Math = Math;
  ExertsList:ExpertModel[] = [];






  ngOnInit(): void {
    this.GetExpertsList().subscribe();
  }

  ChooseExpert(id:number){
    if(this.authService.IsLogined){
      this.router.navigate(['/c/experts/expert/'+id])
    } else{
      /*this.authService.SetChooseExpert(id);
      this.router.navigate(['/auth']);*/
      this.router.navigate(['/expert/'+id])

    }

  }

  GetExpertsList(){
    return this.expertService.GetExpertListPublic(null,0,8)
        .pipe(
            tap((res:any)=>{
              this.ExertsList = res['content'];
            })
        )
  }

}
