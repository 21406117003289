<div>
  <app-slider class="main-slider__slider"
              [SliderPerView]="1"
              [SpaceBetween]="0"
  >
    <swiper-slide *ngIf="slideVisible">
      <li class="how-works__item how-works-first">
        <div class="how-works__row how-works__row_center">
          <div class="how-works__column">
            <ul class="how-works-first__list">
              <li class="how-works-first__item">
                <img class="how-works-first__icon" src='../../../../assets/img/icons/check-circle.svg' alt="icon">
                <b class="how-works-first__text">
                  Ольга Васильева
                </b>
              </li>
              <li class="how-works-first__item">
                <img class="how-works-first__icon" src='../../../../assets/img/icons/check-circle.svg' alt="icon">
                <b class="how-works-first__text">
                  г. Москва
                </b>
              </li>
              <li class="how-works-first__item">
                <img class="how-works-first__icon" src='../../../../assets/img/icons/check-circle.svg' alt="icon">
                <b class="how-works-first__text">
                  Поиск себя и семейные отношения
                </b>
              </li>
            </ul>
          </div>
          <div class="how-works__column how-works-first-text how-works-text">
            <div class="how-works-text__number">
              01.
            </div>
            <div class="how-works-text__texts">
              <div class="how-works-text__subtitle">
                Заполните анкету
              </div>
              <div class="how-works-text__text">
                <p>
                  Предложим системы, которые работают с вашим запросом.
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </swiper-slide>
    <swiper-slide>
      <li class="how-works__item how-works-second">
        <div class="how-works__row">
          <div class="how-works__column how-works-second-text how-works-text">
            <div *ngIf="slideVisible" class="how-works-text__number">
              02.
            </div>
            <div *ngIf="!slideVisible" class="how-works-text__number">
              01.
            </div>
            <div class="how-works-text__texts">
              <div class="how-works-text__subtitle">
                Выберите эксперта
              </div>
              <div class="how-works-text__text">
                <p>
                  Посмотрите, что эксперты рассказывают о себе и своём опыте в анкете. Выберите того, кто интуитивно
                  вызывает симпатию и доверие.
                </p>
              </div>
            </div>
          </div>
          <div class="how-works__column how-works-second__cards">
            <div class="how-works-second__card how-works-second__card_front">
              <div class="how-works-second__img">
                <img class="object-fit" src='../../../../assets/img/Anna.png' alt="icon">
              </div>
              <div class="how-works-second__text">
                Анна Павлова
                <small class="how-works-second__text_small">
                  2 уровень
                </small>
              </div>
            </div>
            <div class="how-works-second__card how-works-second__card_back">
              <div class="how-works-second__img">
                <img class="object-fit" src='../../../../assets/img/Arkady.png' alt="icon">
              </div>
              <div class="how-works-second__text">
                Аркадий Костров
                <small class="how-works-second__text_small">
                  5 уровень
                </small>
              </div>
            </div>
          </div>
        </div>
      </li>
    </swiper-slide>
    <swiper-slide>
      <li class="how-works__item how-works-third">
        <div class="how-works__row">
          <div class="how-works__column">
            <ul class="how-works-third__list">
              <li class="how-works-third__item">
                <div class="how-works-third__text">
                  13 июн, чт
                </div>
              </li>
              <li class="how-works-third__item">
                <div class="how-works-third__text">
                  14 июн, пт
                </div>
              </li>
              <li class="how-works-third__item">
                <div class="how-works-third__text">
                  15 июн, сб
                  <b class="how-works-third__text_bold">
                    14:00
                  </b>
                </div>
              </li>
            </ul>
          </div>
          <div class="how-works__column">
            <div class="how-works__column how-works-third-text how-works-text">
              <div *ngIf="slideVisible" class="how-works-text__number">
                03.
              </div>
              <div *ngIf="!slideVisible" class="how-works-text__number">
                02.
              </div>
              <div class="how-works-text__texts">
                <div class="how-works-text__subtitle">
                  Выберите дату <br>
                  и время консультации
                </div>
                <div class="how-works-text__text">
                  <p>
                    Назначайте и переносите, мы напомним и не дадим вам забыть.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </swiper-slide>
    <swiper-slide *ngIf="slideVisible">
      <li class="how-works__item how-works-fourth">
        <div class="how-works__row">
          <div class="how-works__column">
            <div class="how-works__column how-works-fourth-text how-works-text">
              <div class="how-works-text__number">
                04.
              </div>
              <div class="how-works-text__texts">
                <div class="how-works-text__subtitle">
                  Оплатите консультацию
                </div>
                <div class="how-works-text__content">
                  <div class="how-works-text__text">
                    <p>
                      Оплата картами РФ и иностранных банков. Работайте со своими запросами из любой точки мира.
                    </p>
                  </div>
                  <ul class="how-works-text__list">
                    <li class="how-works-text__item">
                      <img class="how-works-text__img" src='../../../../assets/img/mir.png' alt="">
                    </li>
                    <li class="how-works-text__item">
                      <img class="how-works-text__img" src='../../../../assets/img/visa.png' alt="">
                    </li>
                    <li class="how-works-text__item">
                      <img class="how-works-text__img" src='../../../../assets/img/mastercard.png' alt="">
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="how-works__column how-works-fourth__images">
            <div class="how-works-fourth__images">
              <div class="how-works-fourth__img">
                <img class="object-fit"
                     src='../../../../assets/img/person-paying-with-its-smartphone-wallet-app%202.png'
                     alt="">
              </div>
              <img class="how-works-fourth__img_left" src='../../../../assets/img/squiggle.svg' alt="">
              <img class="how-works-fourth__img_right" src='../../../../assets/img/squiggle.svg' alt="">
            </div>
          </div>
        </div>
      </li>
    </swiper-slide>
    <swiper-slide>
      <li class="how-works__item how-works-fifth">
        <div class="how-works-fifth__row how-works__row">
          <div class="how-works__column how-works-fifth__images">
            <div class="how-works-fifth__images">
              <img class="how-works-fifth__img" src='../../../../assets/img/MacBook%20Pro%2016.png' alt="icon">
              <img class="how-works-fifth__img_left" src='../../../../assets/img/squiggle.svg' alt="">
              <img class="how-works-fifth__img_right" src='../../../../assets/img/squiggle.svg' alt="">
            </div>
          </div>
          <div class="how-works__column">
            <div class="how-works__column how-works-fifth-text how-works-text">
              <div *ngIf="slideVisible" class="how-works-text__number">
                05.
              </div>
              <div *ngIf="!slideVisible" class="how-works-text__number">
                03.
              </div>
              <div class="how-works-text__texts">
                <div class="how-works-text__subtitle">
                  Подключитесь к видео чату<br> к экспертам
                </div>
                <div class="how-works-text__text">
                  <p>
                    На нашей платформе видеоконсультации проходят
                    в защищенном личном кабинете. Конфиденциальные сессии с вашим экспертом доступны из любой точки
                    мира.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </swiper-slide>
  </app-slider>
</div>
