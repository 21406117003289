import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rectangle-component',
  templateUrl: './rectangle.component.html',
  styleUrls: ['./rectangle.component.scss']
})
export class RectangleComponent {
  @Input() text: any;
  @Input() angle: any
  @Input() color: any
}
