<app-crumps-nav [breadcrumbs]="breadcrumbs"></app-crumps-nav>
<div class="container_account">
  <div class="container">
    <div class="notifications__wrap">
      <h2 class="notifications__title title-second">Уведомления</h2>
      <table class="notifications__table">
        <tr class="notifications__tr" *ngFor="let item of notificationList| paginate: config">
          <td class="notifications__td notifications__td_read" [ngClass]="{'notifications__td_unread':!item['read']}">
            <div class="notifications__text">

              <app-notification-item
              [type]="item['notificationType']"
              [role]="role"
              [additionalInfo]="item['additionalInfo']"
              ></app-notification-item>
            </div>
          </td>
          <td class="notifications__td">
            {{item['createdAt']|date:'dd.MM.YYYY':'GMT+3'}}
          </td>
          <td class="notifications__td">
            {{item['createdAt']|date:'HH:mm':'GMT+3'}}
          </td>
          <td class="notifications__td">
            <div class="notifications__status" [ngClass]="{'notifications__status_read':item['read'],'notifications__status__unread':!item['read']}">
              {{item['read']?'Прочитано':'Новое'}}
            </div>
          </td>
        </tr>

      </table>
      <nav class="experts-pagination">
        <pagination-template #p="paginationApi"
                             [id]="config.id"
                             (pageChange)="ChangePage($event)"
                             [maxSize]="7"

        >
          <ul class="experts-pagination__list">
            <li class="experts-pagination__item" (click)="p.previous()">
              <button *ngIf="!p.isFirstPage()"  class="experts-pagination__btn experts-pagination__btn_prev">
                <img alt="Expert Image" class="experts-pagination__svg" src="./assets/img/icons/direction-right%2001.svg">
              </button>
            </li>
            <li class="experts-pagination__item" *ngFor="let page of p.pages"
            >
              <a
                      (click)="p.setCurrent(page.value)"
                      [class.disabled]="p.getCurrent() === page.value"
                      [class.experts-pagination__link_active]="p.getCurrent() === page.value"
                      class="experts-pagination__link">
                {{page.label}}
              </a>
            </li>
            <li class="experts-pagination__item" (click)="p.next()">
              <button *ngIf="!p.isLastPage()"  class="experts-pagination__btn experts-pagination__btn_next">
                <img alt="Expert Image" class="experts-pagination__svg" src="./assets/img/icons/direction-right%2001.svg">
              </button>
            </li>
          </ul>
        </pagination-template>
      </nav>
    </div>
  </div>
</div>
