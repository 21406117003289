import {Component, Input, EventEmitter, Output, OnInit, OnDestroy} from '@angular/core';
import {defineLocale, ruLocale} from "ngx-bootstrap/chronos";
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry} from 'ngx-file-drop';
import {EventsService} from "../../../../../services/events.service";
import {catchError, EMPTY, flatMap, forkJoin, from, mergeMap, Observable, of, Subject, takeUntil} from "rxjs";
import {QuestionsService} from "../../../../../services/questions.service";
import {switchMap, tap} from "rxjs/operators";
import {LoadingService} from "../../../../../services/loading.service";
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";

defineLocale("ru", ruLocale);

@Component({
  selector: 'app-education-item',
  templateUrl: './education-item.component.html',
  styleUrls: ['./education-item.component.scss']
})
export class EducationItemComponent implements OnInit,OnDestroy{

    private destroy$ = new Subject();
    loading = false;
    @Input() main:boolean = true;
    @Input() InitData:any;



    form:any = new FormGroup({
        name:new FormControl('',[Validators.required]),
        place:new FormControl('',[Validators.required]),
        startDate:new FormControl('',[Validators.required]),
        endDate:new FormControl('',[Validators.required]),
        hours: new FormControl('',[Validators.required, Validators.max(32767)])
    });


    get name () {
        return this.form.get('name')!.value
    }

    get place () {
        return this.form.get('place')!.value
    }

    get startDate () {
        return this.form.get('startDate')!.value
    }

    get endDate () {
        return this.form.get('endDate')!.value
    }

    get hours () {
        return this.form.get('hours')!.value
    }




  @Input() Index = 0;
  attachmentIds: any[] = [];

  @Output() OnRemoveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnUpdateEducation: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnUploadFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnRemoveFile: EventEmitter<any> = new EventEmitter<any>();

  maxDate = new Date();

  constructor(private localeService: BsLocaleService,
              private eventsService: EventsService,
              private questionService: QuestionsService,
              public loadingService: LoadingService
              ) {
    this.localeService.use("ru");
  }

  OnRemove(index: number) {
    this.OnRemoveEvent.emit(index);
  }

  UpdateEducation(){
    this.OnUpdateEducation.emit(
        {
              data: {
                name: this.name,
                place: this.place,
                startDate: this.startDate,
                endDate: this.endDate,
                main:this.main,
                hours:this.hours
              },
              index:this.Index
    })
  }


  public  fileSelected(event: Event) {
    let array$:any = [];
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];
        array$.push(file);

      }

      from(array$)
          .pipe(
              mergeMap((res)=>{
                return this.UploadFileAPI(res)
              })
          ).subscribe(()=>{
              this.loading = false;
      });



    }


  }

  UploadFileAPI(file:any){
    const form = new FormData()
    form.append('file',file);
    this.loading = true;
    return this.questionService.SendExpertFile(form)
        .pipe(
            catchError((err)=>{
                if(err.error.message === 'File is bigger then 5Mb'){
                    this.eventsService.throwError(file.name +' размер файла превышает 5 МБ');
                }
              return EMPTY;
            }),
            tap((res:{value:number})=>{
              this.attachmentIds.push(
                  {
                    File:file,
                    id:res.value
                  }
              )
                console.log("value",res);
              this.OnUploadFile.emit({
                id:res.value,
                index: this.Index,
                main:this.main
              })
            })
        );
  }

  public removeFile(index: number,id:number) {
      this.attachmentIds = this.attachmentIds.filter((item: any) => item['id'] !== id);
      this.OnRemoveFile.emit({id,index:this.Index, main:this.main})

    //Delete File
  }

    ngOnInit(): void {
        this.InitDataInput();
        Object.keys(this.form.controls).forEach(key => {
            this.CreateSubscribe(key);
        });

    }

    InitDataInput(){
      console.log("InitDataInput",this.InitData)
      if(this.InitData?.form?.name !== null && this.InitData){
          Object.keys(this.form.controls).forEach(key => {
              if(key === 'startDate' || key === 'endDate'){
                  this.form.controls[key].setValue(this.formatDateFromBack(this.InitData['form'][key]));
              }  else{
                  this.form.controls[key].setValue(this.InitData['form'][key]);
              }
          });
          console.log("InitData",this.InitData);
          this.attachmentIds = [...this.InitData['attachmentIdsInput']
              .map((item1:any)=>
                   {
                       this.OnUploadFile.emit({
                           id:item1.id,
                           index: this.Index,
                           main:this.main
                       })
                       return{
                           id: item1.id,
                           File: {name: item1.name}
                       }

                  })

          ];
      } else{
          this.form.get('hours')!.setValue(this.main?'1':'');
      }

    }

    CreateSubscribe(key:string){
        this.form.get(key)?.valueChanges
      .pipe(
          takeUntil(this.destroy$)
      )
      .subscribe(()=>{
            this.UpdateEducation();
  })
    }

    ValidateForm(){
        for (const i in this.form.controls) {
            this.form.get(i)?.updateValueAndValidity();
        }
        this.form.updateValueAndValidity();
        this.form.markAllAsTouched();
        console.log("")

        return this.form.valid
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    formatDateFromBack (input:any) {
      if(input){
          var datePart = input.match(/\d+/g),
              year = datePart[0], // get only two digits
              month = datePart[1], day = datePart[2];
          return new Date(month+'/'+day+'/'+year);
      } else{
          return null;
      }

    }




}
