<div [formGroup]="form">
  <div class="input-wrap">
    <div class="input-row">
      <div class="input-col">
        <label class="input-label">Укажите ваше образование</label>
      </div>
      <div class="input-col" *ngIf="!main || main && Index!=0">
        <button (click)="OnRemove(Index)" class="input-btn_none_remove input-btn_none input-file__btn_remove"
                type="button">
          <img class="input-svg_remove input-file__svg_remove" src="./assets/img/icons/remove.svg">
          Удалить
        </button>
      </div>
    </div>
    <input class="input-custom"
           formControlName="name"


           type="text"
           placeholder="Введите название">
  </div>
  <div class="input-wrap">
    <label class="input-label">Место обучения, факультет, специальность</label>
    <input class="input-custom"
           formControlName="place"
           type="text"
           placeholder="Введите название">
  </div>
  <div class="input-grid">
    <div class="input-col">
      <div class="input-wrap">
        <label class="input-label">Дата начала обучения</label>
        <div class="container-for-input input-date">
          <input
            #drp="bsDatepicker"
            bsDatepicker
            class="input-custom input-date__input"
            placeholder="дд-мм-гггг"
            [readonly]="true"
            (keydown)="$event.preventDefault();"
            [bsConfig]="{
                                  dateInputFormat: 'DD-MM-YYYY',
                                  adaptivePosition: true,
                                  startView: 'year'
                                }"
            [placement]="'bottom'"
            [maxDate]="maxDate"
            formControlName="startDate"
          >
          <img class="input-date__svg" src="./assets/img/calendar.svg" (click)="drp.toggle()">
        </div>
      </div>
    </div>
    <div class="input-col">
      <div class="input-wrap">
        <label class="input-label">Дата окончания обучения</label>
        <div class="container-for-input input-date">
          <input
            #drp="bsDatepicker"
            bsDatepicker
            class="input-custom input-date__input"
            placeholder="дд-мм-гггг"
            [readonly]="true"
            (keydown)="$event.preventDefault();"
            [bsConfig]="{
                                  dateInputFormat: 'DD-MM-YYYY',
                                  adaptivePosition: true,
                                  startView: 'year'
                                }"
            [placement]="'bottom'"
            [maxDate]="maxDate"
            formControlName="endDate"
          >
          <img class="input-date__svg" src="./assets/img/calendar.svg" (click)="drp.toggle()">
        </div>
      </div>
    </div>
  </div>
  <div class="input-wrap" *ngIf="!main">
    <label class="input-label">Количество часов</label>

    <input class="input-custom"
           min="1"
           step="1"
           max="32767"
           type="number"
           oninput="this.value=this.value.replace(/[^0-9]/g,'');"
           formControlName="hours"
           placeholder="Введите название">
    <div class="error-text" *ngIf="form.get('hours')!.hasError('max')">Кол-во часов не может быть больше 32767</div>
  </div>

  <div class="input-wrap input-file">
    <div class="input-label input-file__label">Изображение диплома и вкладыша</div>
    <label class="input-custom input-file__group">
      Добавить изображение
      <img class="input-file__svg_download" src="./assets/img/icons/Download.svg">
      <input class="input-file__input" type="file" (change)="fileSelected($event)" accept="image/*" multiple>
    </label>
    <app-preloader *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.ADD_EDUCATION_IMAGE
                  )
                ].value && loading;else images">
    </app-preloader>
    <ng-template #images>
      <ul class="input-file__list" *ngIf="attachmentIds.length !== 0">
      <li class="input-file__item" *ngFor="let item of attachmentIds; let i = index">
        <p>{{item.id}}</p>
        <div class="input-file__name input-label">
          {{ item.File.name }}
        </div>
        <button class="input-btn_none_remove input-btn_none input-file__btn_remove" type="button"
                (click)="removeFile(i,item.id)">
          <img class="input-svg_remove input-file__svg_remove" src="./assets/img/icons/remove.svg">
          Удалить
        </button>
      </li>
    </ul>
    </ng-template>
  </div>
</div>
