import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  constructor(protected mainService: MainService, private activatedRoute: ActivatedRoute) {
    // this.activatedRoute.data.subscribe(({ user }) => {
    //   // do something with your resolved data ...
    //   console.log("MAIN COMPONENT user = ", user)
    // })
  }
  
}
