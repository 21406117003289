import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-public-expert',
  templateUrl: './public-expert.component.html',
  styleUrls: ['./public-expert.component.scss']
})
export class PublicExpertComponent implements OnInit{


  ExpertId = 0;
  breadcrumbs: Array<{ text: string, link: string }> = [
    {
      text: 'Главная',
      link: '/landing'
    },
    {
      text: 'Эксперты',
      link: '/experts'
    },
    {
      text: 'Выбранный эксперт',
      link: ''
    }
  ];

  constructor(
      private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res)=>{
      this.ExpertId = +res['id'];
    })
  }

}
