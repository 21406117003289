import { Component, OnDestroy } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}  

declare global {
  interface Window {
    jivo_destroy?: any;
  }
}

@Component({
  selector: 'app-about',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnDestroy {
  private ScriptStore: Scripts[] = [
    {name: 'jivoOnline', src: 'https://code.jivo.ru/widget/QVqJp8bror'},
  ];
  private scripts: any = {};

  constructor() {
    console.log(window)
    this.ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
          loaded: false,
          src: script.src
      };
    });
    this.load();
  }

  load() {
    var promises: any[] = [];
    (Object.keys(this.scripts)).forEach((script: any) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
      return new Promise((resolve, reject) => {
          if (this.scripts[name].loaded) {
              resolve({script: name, loaded: true, status: 'Already Loaded'});
          }
          else {
              let script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = this.scripts[name].src;
              script.onload = () => {
                console.log("script onload")
                  this.scripts[name].loaded = true;
                  resolve({script: name, loaded: true, status: 'Loaded'});
              };
              script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
              document.getElementsByTagName('head')[0].appendChild(script);

          }
      });
  }

  ngOnDestroy(): void {
    window.jivo_destroy();
    let d = document.getElementsByTagName('script');
    let srcToDelete = this.ScriptStore.map(x=>x.src)
    for(let i = 0; i< d.length; i++) {
      if(srcToDelete.includes(d[i].src)) {
        d[i].parentNode?.removeChild(d[i]);
      }
    }
  }
}
