<div class="auth__router">
    <div class="container">

        <div *ngFor="let breadcrumb of breadcrumbs; let i=index">
            <a routerLink={{breadcrumb.link}}
               [ngClass]="{'is-active':this.breadcrumbs.length-1===i }"
            >{{breadcrumb.text}}</a>
            <p *ngIf="i < breadcrumbs.length - 1">❯</p>
        </div>
    </div>
</div>
