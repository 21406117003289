import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { EventsService } from './services/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  apiUrl = `${environment.apiUrl}`;
  stage = `${environment.stage}`;

  isShowConfig = false;
  isLoadingUser = true;

  private destroy$ = new Subject();

  constructor(
    protected authService: AuthService,
    private events: EventsService,
    private toastr: ToastrService
  ) {
    this.authService.CheckToken();

    this.authService.IsLoginedChange$.subscribe((val) => {
      this.isLoadingUser = false;
    });
  }

  ngOnInit() {
    this.InitToast();
  }

  InitToast() {
    this.events.IsErrorChange$.pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        if (res && this.events.Error.status) {
          this.toastr.error(this.events.Error.text, 'Ошибка!', {
            positionClass: 'toast-bottom-center',
            titleClass: 'title-toast',
          });
        } else {
          this.toastr.clear();
        }
      }
    );

    this.events.IsSuccessChange$.pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        if (res && this.events.Success.status) {
          this.toastr.success(
            this.events.Success.text,
            'Операция выполнена успешно',
            {
              positionClass: 'toast-bottom-center',
              titleClass: 'title-toast',
            }
          );
        } else {
          this.toastr.clear();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
