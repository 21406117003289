<app-crumps-nav [breadcrumbs]="breadcrumbs"></app-crumps-nav>
<div class="container_account">
  <section class="main" id="main">
    <div class="main__container container">
      <div class="main__head">
        <div class="main__col">
          <h2 class="main__title title-second">
            Мы рады сотрудничеству с каждым экспертом
          </h2>
        </div>
        <div class="main__col main__col_text">
          <div class="main__caption title-second__caption">
            Если вы хотите работать в нашей команде, заполните контактную информацию ниже. Наши операторы свяжутся с
            вами в ближайшее время
          </div>
        </div>
      </div>
      <form class="main__form" [formGroup]="form" (ngSubmit)="Send()">
        <div class="main__group input-wrap">
          <label class="main__label input-label">Ваше имя</label>
          <input class="main__input input-custom" formControlName="name" type="text" placeholder="Введите имя">
        </div>
        <div class="main__group input-wrap">
          <label class="main__label input-label">Номер телефона</label>
          <input class="main__input input-custom" phone formControlName="phone" required type="text"
                 placeholder="+7 (123) 456-78-90">
        </div>
        <div class="main__group input-wrap">
          <label class="main__label input-label">Почта</label>
          <input class="main__input input-custom" formControlName="email" type="email" placeholder="Введите почту">
          <div class="error-text" *ngIf="form.get('email')?.hasError('email') && this.form.get('email')!.touched">Неверный формат email.</div>
        </div>
        <button class="main__btn btn-custom btn-approve" type="submit">
          Отправить
        </button>
      </form>
    </div>
  </section>
</div>
