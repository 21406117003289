import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientsService } from 'src/app/services/clients.service';

export abstract class AbstractReviewService {
  abstract save(
    sessionId: number,
    payload: {
      comment: string;
      rating: number;
    }
  ): Observable<unknown>;
}

@Injectable()
export class ReviewService extends AbstractReviewService {
  private readonly clientService = inject(ClientsService);

  override save(
    sessionId: number,
    payload: {
      comment: string;
      rating: number;
    }
  ): Observable<unknown> {
    return this.clientService.SetCommentAndRatingOfSession(sessionId, payload);
  }
}
