import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { ModalContainerComponent } from 'src/app/_modal/modal-container.component';
import { Modal, ModalRef } from 'src/app/_modal/modal-options';

//TODO don't user provideIn 'root'
@Injectable({ providedIn: 'root' })
export class ModalService {
  private modalContainer!: HTMLElement;
  private modalContainerFactory!: ComponentFactory<ModalContainerComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private readonly injector: Injector
  ) {
    this.setupModalContainerFactory();
  }

  open<T extends Modal, I>(component: Type<T>, inputs?: I): ModalRef {
    this.setupModalContainerDiv();

    const modalContainerRef = this.appRef.bootstrap(
      this.modalContainerFactory,
      this.modalContainer
    );

    const modalComponentRef = modalContainerRef.instance.createModal(
      component,
      this.injector
    );

    if (inputs) {
      modalComponentRef.instance.onInjectInputs(inputs);
    }

    return new ModalRef(modalContainerRef, modalComponentRef);
  }

  private setupModalContainerDiv(): void {
    this.modalContainer = document.createElement('div');
    document.getElementsByTagName('body')[0].append(this.modalContainer);
  }

  private setupModalContainerFactory(): void {
    this.modalContainerFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        ModalContainerComponent
      );
  }
}
