import { FormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './slider/slider.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RectangleComponent } from './rectangle-component/rectangle.component';
import { CrumpsNavComponent } from './crumps-nav/crumps-nav.component';
import { PhoneDirective } from './directives/phone.directive';
import { PreloaderComponent } from './preloader/preloader.component';
import { ImagePreloaderComponent } from './image-preloader/image-preloader.component';
import { StepsLineComponent } from './steps-line/steps-line.component';
import { ExpertCardComponent } from './expert-card/expert-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExpertPageComponent } from './expert-page/expert-page.component';
import { ModalModule } from '../_modal';
import { imagePipe } from '../pipes/image.pipe';
import { HowWorksSliderComponent } from '../features/main/experts/how-works-slider/how-works-slider.component';
import { ViewedExpertsComponent } from './viewed-experts/viewed-experts.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChangingThePasswordModalComponent } from './changing-the-password-modal/changing-the-password-modal.component';
import { EducationItemComponent } from '../features/expert-module/expert-questions/expert-education-questions/education-item/education-item.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { CancelTheConsultationModalComponent } from './cancel-the-consultation-modal/cancel-the-consultation-modal.component';
import { LeaveReviewModalComponent } from './leave-review-modal/leave-review-modal.component';
import { ExpertPageConsultationModalComponent } from './expert-page/expert-page-consultation-modal/expert-page-consultation-modal.component';
import { ClientInfoModalComponent } from './client-info-modal/client-info-modal.component';
import { PayStatusPipe } from '../pipes/pay-status.pipe';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { SessionPipe } from '../pipes/session.pipe';
import { ImageDirective } from '../directives/image.directive';
import { NotificationItemComponent } from './notifications-list/notification-item/notification-item.component';
import {MonthPipe} from "../pipes/month.pipe";
import { ExpertChooseModalComponent } from './expert-choose-modal/expert-choose-modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    RectangleComponent,
    CrumpsNavComponent,
    PhoneDirective,
    PreloaderComponent,
    ImagePreloaderComponent,
    StepsLineComponent,
    ExpertCardComponent,
    ExpertPageComponent,
    imagePipe,
    PayStatusPipe,
    MonthPipe,
    HowWorksSliderComponent,
    ViewedExpertsComponent,
    ChangingThePasswordModalComponent,
    EducationItemComponent,
    CancelTheConsultationModalComponent,
    LeaveReviewModalComponent,
    ExpertPageConsultationModalComponent,
    ClientInfoModalComponent,
    NotificationsListComponent,
    SessionPipe,
    NotificationItemComponent,
    ExpertChooseModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    ModalModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    BsDatepickerModule,
    NgSelectModule,
    NgxFileDropModule,
    ImageDirective,
    NgxPaginationModule,
  ],
  providers: [],
  exports: [
    HeaderComponent,
    SliderComponent,
    FooterComponent,
    CrumpsNavComponent,
    PhoneDirective,
    PreloaderComponent,
    ImagePreloaderComponent,
    StepsLineComponent,
    ExpertCardComponent,
    ExpertPageComponent,
    ModalModule,
    HowWorksSliderComponent,
    ViewedExpertsComponent,
    ChangingThePasswordModalComponent,
    EducationItemComponent,
    ImageDirective,
    imagePipe,
    PayStatusPipe,
    NotificationsListComponent,
    SessionPipe,
    MonthPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UIModule {}
